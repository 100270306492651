import React, { useEffect, useState, useRef } from "react";
import Layout from "../components/Layout";
import PageHeader from "../components/shared/PageHeader";
import { View } from "../styles/bookings.styles";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import ReactTooltip from "react-tooltip";
import { useDebounce } from "rooks";
import { ReactComponent as SortIcon } from "../assets/icons/sort-icon.svg";
import { ReactComponent as PrevIcon } from "../assets/icons/prev-icon.svg";
import { ReactComponent as NextIcon } from "../assets/icons/next-icon.svg";
import { ReactComponent as FilterIcon } from "../assets/icons/filter-icon.svg";
import { ReactComponent as UploadIcon } from "../assets/icons/upload-icon.svg";
import ModalLoader from "../components/shared/Loader";
import useOnClickOutside from "../hooks/useClickOutside";
import {
  bookingSelector,
  fetchBookings,
  filterBookings,
  searchBookings,
} from "../redux/features/bookingSlice";

const Bookings = () => {
  const dispatch = useDispatch();
  const ref = useRef();
  const { bookings, bookingsMeta, loading } = useSelector(bookingSelector);
  const [page, setPage] = useState(bookingsMeta?.current_page || 1);
  const [rows, setRows] = useState(10);
  const [search, setSearch] = useState("");
  const [date, setDate] = useState("");
  const [booking_date, setBookingDate] = useState("");
  const [openFilterMenu, setOpenFilterMenu] = useState(false);

  useOnClickOutside(ref, () => setOpenFilterMenu(false));
  const setValueDebounced = useDebounce(setSearch, 1000);

  useEffect(() => {
    dispatch(fetchBookings(page, rows));
  }, [dispatch, page, rows]);

  const fetchNextPage = () => {
    if (bookingsMeta?.last_page > bookingsMeta?.current_page) {
      setPage(bookingsMeta?.current_page + 1);
    }
  };

  const fetchPrevPage = () => {
    if (bookingsMeta?.current_page > 1) {
      setPage(bookingsMeta?.current_page - 1);
    }
  };

  const handleSearch = () => {
    dispatch(filterBookings(date, booking_date));
  };

  useEffect(() => {
    dispatch(searchBookings(search));

    // eslint-disable-next-line
  }, [search]);

  return (
    <Layout>
      <View>
        <PageHeader title='Bookings' />

        <div className='content'>
          <div className='head'>
            <Link to='/book-diagnosis'>Book an Appointment</Link>
          </div>
          <div className='table-view'>
            <div className='header'>
              <div className='search-view'>
                {openFilterMenu && (
                  <div className='filter-box' ref={ref}>
                    <div className='head'>
                      <FilterIcon /> <span>Filter</span>
                    </div>
                    <div className='input'>
                      <label htmlFor='date'>
                        Date{" "}
                        <button data-tip data-for='dateTip'>
                          ?
                        </button>
                        <ReactTooltip
                          id='dateTip'
                          place='bottom'
                          effect='solid'
                        >
                          Filter by the date the booking was made.
                        </ReactTooltip>
                      </label>
                      <input
                        type='date'
                        name='date'
                        onChange={(e) => setDate(e.target.value)}
                      />
                    </div>
                    <div className='input'>
                      <label htmlFor='booking_date'>
                        Booking Date{" "}
                        <button data-tip data-for='bookingTip'>
                          ?
                        </button>
                        <ReactTooltip
                          id='bookingTip'
                          place='bottom'
                          effect='solid'
                        >
                          Filter by the date the booking is scheduled for.
                        </ReactTooltip>
                      </label>
                      <input
                        type='date'
                        name='date'
                        onChange={(e) => setBookingDate(e.target.value)}
                      />
                    </div>
                    <div className='cta'>
                      <button
                        className='clear'
                        onClick={() => dispatch(fetchBookings(page, rows))}
                      >
                        Clear
                      </button>
                      <button onClick={handleSearch}>Filter</button>
                    </div>
                  </div>
                )}
                <input
                  type='text'
                  onChange={(e) => setValueDebounced(e.target.value)}
                  placeholder='Search by job no, vin, vehicle make, model and license_no'
                />
                <button
                  data-tip
                  data-for='filterTip'
                  onClick={() => setOpenFilterMenu(true)}
                >
                  <FilterIcon />
                </button>
                <ReactTooltip id='filterTip' place='bottom' effect='solid'>
                  Filter
                </ReactTooltip>
              </div>

              <button className='export'>
                <UploadIcon /> <span>Export</span>
              </button>
            </div>

            <div className='table-head'>
              <div className='id'>
                <span>Booking No</span>
                <button>
                  <SortIcon />
                </button>
              </div>
              {/* <div className='vin'>
                <span>VIN</span>
                <button>
                  <SortIcon />
                </button>
              </div> */}
              <div className=''>
                <span>Worshop</span>
                <button>
                  <SortIcon />
                </button>
              </div>
              <div className='date'>
                <span>Date Booked</span>
                <button>
                  <SortIcon />
                </button>
              </div>
              <div className='date'>
                <span>Date Scheduled</span>
                <button>
                  <SortIcon />
                </button>
              </div>
              <div className='status'>
                <span>Status</span>
                <button>
                  <SortIcon />
                </button>
              </div>

              {/* <div className='action'>
                <span>Actions</span>
              </div> */}
            </div>

            {bookings?.map((item) => {
              return (
                <div className='table-item' key={item?.id}>
                  <div className='id'>
                    <span>{item?.booking_number}</span>
                  </div>
                  {/* <div className='vin'>
                    <span>{item?.vin?.substr(0, 15)}...</span>
                  </div> */}
                  <div className=''>
                    <span>
                      {item?.workshop?.name || "Not yet assigned"}{" "}
                      {item?.workshop?.city}
                    </span>
                  </div>
                  <div className='date'>
                    <span>
                      {format(new Date(item?.created_at), "do MMM, yyyy")}
                    </span>
                  </div>
                  <div className='date'>
                    <span>
                      {format(new Date(item?.booking_date), "do MMM, yyyy")}
                    </span>
                  </div>
                  <div
                    className={`status ${
                      item?.status === "pending"
                        ? "open"
                        : item?.status === "Approved"
                        ? "closed"
                        : ""
                    }`}
                  >
                    <span>{item?.status}</span>
                  </div>
                  {/* <div className='action'>
                    <Link to={`/service-history/${item?.id}`}>View</Link>
                  </div> */}
                </div>
              );
            })}

            {bookings?.length < 1 ? (
              <div className='empty'>
                <p>No result found</p>
              </div>
            ) : null}

            {bookingsMeta !== {} ? (
              <div className='bottom'>
                <div className='pagination'>
                  <button onClick={fetchPrevPage}>
                    <PrevIcon />
                  </button>
                  <span>
                    {bookingsMeta?.current_page} of {bookingsMeta?.last_page}
                  </span>
                  <button onClick={fetchNextPage}>
                    <NextIcon />
                  </button>
                </div>

                <div className='row'>
                  <span>Show:</span>
                  <select name='rows' onChange={(e) => setRows(e.target.value)}>
                    <option value='10' defaultChecked>
                      10 rows
                    </option>
                    <option value='15' defaultChecked>
                      15 rows
                    </option>
                    <option value='20' defaultChecked>
                      20 rows
                    </option>
                  </select>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </View>
      <ModalLoader showModal={loading} />
    </Layout>
  );
};

export default Bookings;
