import styled from "styled-components";
import bg from "../assets/images/bg-image.svg";
import { device } from "../constants/breakpoints";

export const View = styled.section`
  width: 100vw;
  height: 100vh;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media ${device.phone} {
      width: 100vw;
      flex-direction: column;
    }

    .left-side {
      width: 50%;
      height: 100vh;
      background: url(${bg});
      padding: 40px;
      position: relative;

      @media ${device.phone} {
        width: 100vw;
        height: 30vh;
        padding-bottom: 20px;
      }

      .bg {
        width: 100%;
        height: 100%;
      }

      .info {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: 150px;
        padding: 0 40px;

        h2 {
          max-width: 400px;
          color: ${({ theme }) => theme.white};
          font-size: 3rem;
          line-height: 50px;

          @media ${device.phone} {
            font-size: 1.5rem;
            margin-top: 20px;
            display: none;
          }
        }

        p {
          font-size: 1.125rem;
          color: ${({ theme }) => theme.white};
          margin-top: 10px;

          @media ${device.phone} {
            display: none;
          }
        }
      }
    }

    .right-side {
      width: 50%;
      height: 100%;
      align-items: center;
      justify-content: center;
      display: flex;
      overflow-y: scroll;

      @media ${device.phone} {
        width: 100%;
        padding: 0 20px;
        margin-bottom: 20px;
        padding-top: 120px;
      }

      .form {
        width: 65%;

        @media ${device.phone} {
          width: 100%;
        }

        h3 {
          font-size: 1.5rem;
          font-weight: 500;
          margin-bottom: 30px;
        }

        form {
          width: 100%;
          display: flex;
          flex-direction: column;

          .input-view {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
            position: relative;

            label {
              font-weight: 500;
              color: ${({ theme }) => theme.grey_500};
              font-size: 0.875rem;
            }

            p {
              font-weight: 400;
              color: ${({ theme }) => theme.red_500};
              font-size: 0.875rem;
            }

            input {
              height: 45px;
              border: 1px solid ${({ theme }) => theme.grey_200};
              border-radius: 4px;
              outline: none;
              padding: 10px;
              margin-top: 3px;

              &::placeholder {
                color: ${({ theme }) => theme.grey_300};
                font-size: 0.9rem;
              }

              &:focus {
                border: 1px solid ${({ theme }) => theme.black};
              }
            }

            button {
              position: absolute;
              top: 35px;
              right: 10px;
              background: transparent;
              border: none;
              outline: none;
              cursor: pointer;
            }
          }

          .terms {
            width: 100%;
            display: 100%;
            display: flex;
            align-items: center;

            button {
              background: transparent;
              border: none;
              outline: none;
              color: ${({ theme }) => theme.blue_600};
              padding: 0;
              cursor: pointer;
              margin-right: 10px;
            }

            a {
              color: ${({ theme }) => theme.blue_600};
            }
          }

          .btn {
            background: ${({ theme }) => theme.blue};
            border: none;
            outline: none;
            color: ${({ theme }) => theme.white};
            padding: 0;
            cursor: pointer;
            border-radius: 4px;
            margin-top: 30px;
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .register {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 20px;

          a {
            color: ${({ theme }) => theme.blue_600};
            text-decoration: none;
          }
        }
      }
    }
  }
`;
