import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { format } from "date-fns";
import Layout from "../components/Layout";
import PageHeader from "../components/shared/PageHeader";
import { fetchJob, jobsSelector } from "../redux/features/jobsSlice";
import { View } from "../styles/job.styles";
import ModalLoader from "../components/shared/Loader";
import { ReactComponent as HubIcon } from "../assets/icons/hub-icon.svg";
import { ReactComponent as LocationIcon } from "../assets/icons/location-icon.svg";
import { ReactComponent as CalenderIcon } from "../assets/icons/calender-icon.svg";
import Overview from "../components/jobComponents/Overview";
import Quotes from "../components/jobComponents/Quotes";

const Job = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { job, loading } = useSelector(jobsSelector);
  const [activeView, setActiveView] = useState(0);

  /*
  When the component mounts, fetch the job with the given id.
  
  Args:
    None
  Returns:
    None
  */
  useEffect(() => {
    dispatch(fetchJob(id));
  }, [dispatch, id]);

  /*
 This is the job page. It displays the job card, the job status, and the job quotes.
 */
  return (
    <Layout>
      <View>
        <PageHeader title='Job' />

        {/* ==== Header View ==== */}
        <div className='header-view'>
          <div className='id'>
            <h3>{job?.card_ref}</h3>
            <span
              className={`status ${
                job?.status === "Open"
                  ? "open"
                  : job?.status === "Approved"
                  ? "closed"
                  : ""
              }`}
            >
              {job?.status}
            </span>
          </div>
          <div className='location-view'>
            <div className='hub'>
              <HubIcon /> <span>{job?.excellence_center?.name}</span>
            </div>
            <div className='hub'>
              <LocationIcon /> <span>{job?.excellence_center?.address}</span>
            </div>
            <div className='hub'>
              <CalenderIcon />{" "}
              <span>
                {format(
                  new Date(job?.updated_at || Date.now()),
                  "do MMM, yyyy"
                )}
              </span>
            </div>
          </div>

          {/* =====TAB VIEW ==== */}
          <div className='tab-view'>
            <button
              onClick={() => setActiveView(0)}
              className={activeView === 0 ? "active" : ""}
            >
              Overview
            </button>
            <button
              onClick={() => setActiveView(1)}
              className={activeView === 1 ? "active" : ""}
            >
              Quotes
            </button>
          </div>
        </div>

        <div className='content'>
          {activeView === 0 && <Overview job={job} setActiveView={setActiveView} />}
          {activeView === 1 && <Quotes job={job} />}
        </div>
      </View>
      <ModalLoader showModal={loading} />
    </Layout>
  );
};

export default Job;
