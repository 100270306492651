import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import ReactDom from "react-dom";
import { device } from "../../constants/breakpoints";

const backdrop = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

const ModalOverlay = (props) => {
  // Generate content to be dsisplayed in modal
  const content = (
    <Content>
      <div className={props.className}>{props.children}</div>
    </Content>
  );

  return ReactDom.createPortal(content, document.getElementById("modal-hook"));
};

const InputModal = (props) => {
  return (
    <AnimatePresence exitBeforeEnter>
      {props.showModal && (
        <ModalView
          className='backdrop'
          variants={backdrop}
          initial='hidden'
          animate='visible'
        >
          <ModalOverlay {...props} />
        </ModalView>
      )}
    </AnimatePresence>
  );
};

export default InputModal;

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
`;

const Content = styled.div`
  background-color: ${(props) => props.theme.white};
  z-index: 20000;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  border-radius: 5px;
  width: 35%;
  padding: 20px 0px;

  @media ${device.tablet} {
    width: 90%;
    height: 80%;
    overflow-y: scroll;
  }
  @media ${device.phone} {
    width: 90%;
    height: 80%;
    overflow-y: scroll;
  }

  header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    border-bottom: 1px solid ${(props) => props.theme.grey_200};
    padding: 0 20px;
    padding-bottom: 10px;

    h3 {
      font-weight: 500;
    }

    button {
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;

      img {
        width: 12px;
        height: 12px;
        object-fit: contain;
      }
    }
  }

  p {
    padding: 0 20px;
    color: ${(props) => props.theme.grey_400};
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.white};
    padding: 20px;

    @media ${device.tablet} {
      width: 100%;
    }
    @media ${device.phone} {
      width: 100%;
    }

    .input-view {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      position: relative;

      @media ${device.tablet} {
        width: 100%;
      }
      @media ${device.phone} {
        width: 100%;
      }

      label {
        font-weight: 500;
        color: ${({ theme }) => theme.grey_500};
        font-size: 0.875rem;
      }

      p {
        font-weight: 400;
        color: ${({ theme }) => theme.red_500};
        font-size: 0.875rem;
      }

      input,
      select {
        height: 45px;
        border: 1px solid ${({ theme }) => theme.grey_200};
        border-radius: 4px;
        outline: none;
        padding: 10px;
        margin-top: 3px;
        background-color: transparent;

        &::placeholder {
          color: ${({ theme }) => theme.grey_300};
          font-size: 0.9rem;
        }

        &:focus {
          border: 1px solid ${({ theme }) => theme.black};
        }
      }

      button {
        position: absolute;
        top: 32px;
        right: 10px;
        background: transparent;
        border: none;
        outline: none;
        cursor: pointer;
      }
    }

    .row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media ${device.tablet} {
        flex-direction: column;
        justify-content: flex-start;
      }
      @media ${device.phone} {
        flex-direction: column;
        justify-content: flex-start;
      }

      .input-view {
        width: 49%;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        position: relative;

        @media ${device.tablet} {
          width: 100%;
        }
        @media ${device.phone} {
          width: 100%;
        }

        label {
          font-weight: 500;
          color: ${({ theme }) => theme.grey_500};
          font-size: 0.875rem;
        }

        p {
          font-weight: 400;
          color: ${({ theme }) => theme.red_500};
          font-size: 0.875rem;
        }

        input,
        select {
          height: 45px;
          border: 1px solid ${({ theme }) => theme.grey_200};
          border-radius: 4px;
          outline: none;
          padding: 10px;
          margin-top: 3px;
          background-color: transparent;

          &::placeholder {
            color: ${({ theme }) => theme.grey_300};
            font-size: 0.9rem;
          }

          &:focus {
            border: 1px solid ${({ theme }) => theme.black};
          }
        }

        button {
          position: absolute;
          top: 32px;
          right: 10px;
          background: transparent;
          border: none;
          outline: none;
          cursor: pointer;
        }
      }
    }

    .cta {
      button {
        background: ${({ theme }) => theme.black};
        border: none;
        outline: none;
        color: ${({ theme }) => theme.white};
        padding: 0;
        cursor: pointer;
        border-radius: 4px;
        margin-top: 30px;
        height: 45px;
        width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media ${device.phone} {
          width: 100%;
        }
      }
    }
  }
`;
