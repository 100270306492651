import styled from "styled-components";
import { device } from "../constants/breakpoints";

export const View = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px;
  padding-top: 60px;
  overflow-y: scroll;
  background-color: ${({ theme }) => theme.grey_100};
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media ${device.tablet} {
    width: 100vw;
    padding: 0 40px;
    padding-top: 60px;
  }

  @media ${device.phone} {
    width: 100vw;
    padding: 0 20px;
    padding-top: 60px;
  }

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    @media ${device.phone} {
      justify-content: flex-start;
    }
    form {
      width: 60%;
      display: flex;
      flex-direction: column;
      background-color: ${({ theme }) => theme.white};
      padding: 20px;
      margin-top: 40px;

      @media ${device.tablet} {
        width: 100%;
      }
      @media ${device.phone} {
        width: 100%;
      }

      .row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media ${device.phone} {
          flex-direction: column;
          justify-content: flex-start;
        }

        .input-view {
          width: 49%;
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;
          position: relative;

          @media ${device.phone} {
            width: 100%;
          }

          label {
            font-weight: 500;
            color: ${({ theme }) => theme.grey_500};
            font-size: 0.875rem;
          }

          p {
            font-weight: 400;
            color: ${({ theme }) => theme.red_500};
            font-size: 0.875rem;
          }

          input,
          select {
            height: 45px;
            border: 1px solid ${({ theme }) => theme.grey_200};
            border-radius: 4px;
            outline: none;
            padding: 10px;
            margin-top: 3px;
            background-color: transparent;

            &::placeholder {
              color: ${({ theme }) => theme.grey_300};
              font-size: 0.9rem;
            }

            &:focus {
              border: 1px solid ${({ theme }) => theme.black};
            }
          }

          button {
            position: absolute;
            top: 32px;
            right: 10px;
            background: transparent;
            border: none;
            outline: none;
            cursor: pointer;
          }
        }
      }

      .cta {
        button {
          background: ${({ theme }) => theme.blue};
          border: none;
          outline: none;
          color: ${({ theme }) => theme.white};
          padding: 0;
          cursor: pointer;
          border-radius: 4px;
          margin-top: 30px;
          height: 45px;
          width: 150px;
          display: flex;
          align-items: center;
          justify-content: center;

          @media ${device.phone} {
            width: 100%;
          }
        }
      }

      .input-view {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        position: relative;

        @media ${device.phone} {
          width: 100%;
        }

        label {
          font-weight: 500;
          color: ${({ theme }) => theme.grey_500};
          font-size: 0.875rem;
        }

        p {
          font-weight: 400;
          color: ${({ theme }) => theme.red_500};
          font-size: 0.875rem;
        }

        input,
        select {
          height: 45px;
          border: 1px solid ${({ theme }) => theme.grey_200};
          border-radius: 4px;
          outline: none;
          padding: 10px;
          margin-top: 3px;
          background-color: transparent;

          &::placeholder {
            color: ${({ theme }) => theme.grey_300};
            font-size: 0.9rem;
          }

          &:focus {
            border: 1px solid ${({ theme }) => theme.black};
          }
        }

        button {
          position: absolute;
          top: 32px;
          right: 10px;
          background: transparent;
          border: none;
          outline: none;
          cursor: pointer;
        }
      }

      .workshops {
        position: relative;
        width: 100%;

        .icon {
          position: absolute;
          top: 43px;
          right: 12px;
        }

        .options {
          position: absolute;
          width: 100%;
          top: 70px;
          min-height: 100px;
          max-height: 300px;
          overflow-y: scroll;
          background-color: ${({ theme }) => theme.white};
          border: 1px solid ${({ theme }) => theme.grey_200};
          border-radius: 4px;

          .option {
            padding: 10px;
            transition: all 0.3s ease;
            cursor: pointer;

            &:hover {
              background-color: ${({ theme }) => theme.grey_200};
            }
          }
        }
      }
    }
  }
`;
