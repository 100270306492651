import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import userSlice from "./features/userSlice";
import vehicleSlice from "./features/vehicleSlice";
import jobsSlice from "./features/jobsSlice";
import subscriptionSlice from "./features/subscriptionSlice";
import bookingSlice from "./features/bookingSlice";

const persistConfig = {
  key: "root",
  storage,
};

const reducers = combineReducers({
  user: userSlice,
  vehicles: vehicleSlice,
  jobs: jobsSlice,
  subscriptions: subscriptionSlice,
  bookings: bookingSlice,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
  reducer: persistedReducer,
});
