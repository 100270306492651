import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { format } from "date-fns";
// import ReactTooltip from "react-tooltip";
// import { useDebounce } from "rooks";
import {
  fetchQuotes,
  jobsSelector,
  setQuote,
} from "../../redux/features/jobsSlice";
import { ReactComponent as SortIcon } from "../../assets/icons/sort-icon.svg";
import { ReactComponent as PrevIcon } from "../../assets/icons/prev-icon.svg";
import { ReactComponent as NextIcon } from "../../assets/icons/next-icon.svg";
// import { ReactComponent as FilterIcon } from "../../assets/icons/filter-icon.svg";
import { ReactComponent as UploadIcon } from "../../assets/icons/upload-icon.svg";
// import useOnClickOutside from "../../hooks/useClickOutside";
import styled from "styled-components";
import { device } from "../../constants/breakpoints";
import { numberWithCommas } from "../../utils/numberWithCommas";

const Quotes = ({ job }) => {
  const dispatch = useDispatch();
  //   const ref = useRef();
  const { quotes, quotesMeta } = useSelector(jobsSelector);
  const [page, setPage] = useState(quotesMeta?.current_page || 1);
  const [rows, setRows] = useState(10);
  //   const [search, setSearch] = useState("");
  //   const [status, setStatus] = useState("");
  //   const [service_type, setServiceType] = useState("");
  //   const [openFilterMenu, setOpenFilterMenu] = useState(false);

  //   useOnClickOutside(ref, () => setOpenFilterMenu(false));
  //   const setValueDebounced = useDebounce(setSearch, 1000);

  useEffect(() => {
    dispatch(fetchQuotes(job?.card_ref, rows, page));
  }, [dispatch, page, rows, job.card_ref]);

  const fetchNextPage = () => {
    if (quotesMeta?.last_page > quotesMeta?.current_page) {
      setPage(quotesMeta?.current_page + 1);
    }
  };

  const fetchPrevPage = () => {
    if (quotesMeta?.current_page > 1) {
      setPage(quotesMeta?.current_page - 1);
    }
  };

  //   const handleSearch = () => {
  //     dispatch(filterRequests(status, service_type));
  //   };

  //   useEffect(() => {
  //     dispatch(searchJobs(search));
  //   }, [search, dispatch]);

  return (
    <View>
      <div className='table-view'>
        <div className='header'>
          <div className='search-view'>
            {/* {openFilterMenu && (
              <div className='filter-box' ref={ref}>
                <div className='head'>
                  <FilterIcon /> <span>Filter</span>
                </div>
                <div className='input'>
                  <select
                    name='status'
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value='' defaultChecked>
                      Status
                    </option>
                    <option value='open'>Open</option>
                    <option value='approved'>Approved</option>
                  </select>
                </div>
                <div className='input'>
                  <select
                    name='service_type'
                    onChange={(e) => setServiceType(e.target.value)}
                  >
                    <option value='' defaultChecked>
                      Service Type
                    </option>
                    <option value='pms'>PMS</option>
                  </select>
                </div>
                <div className='cta'>
                  <button
                    className='clear'
                    onClick={() => dispatch(fetchQuotes(page, rows))}
                  >
                    Clear
                  </button>
                  <button>Filter</button>
                </div>
              </div>
            )}
            <input
              type='text'
              onChange={(e) => setValueDebounced(e.target.value)}
              placeholder='Search by job no, vin, vehicle make, model and license_no'
            />
            <button
              data-tip
              data-for='filterTip'
              onClick={() => setOpenFilterMenu(true)}
            >
              <FilterIcon />
            </button>
            <ReactTooltip id='filterTip' place='bottom' effect='solid'>
              Filter
            </ReactTooltip> */}
          </div>

          <button className='export'>
            <UploadIcon /> <span>Export</span>
          </button>
        </div>

        <div className='table-head'>
          <div className='id'>
            <span>Job No</span>
            <button>
              <SortIcon />
            </button>
          </div>
          <div className='total'>
            <span>Total</span>
            <button>
              <SortIcon />
            </button>
          </div>
          {/* <div className='status'>
            <span>Status</span>
            <button>
              <SortIcon />
            </button>
          </div> */}
          <div className='date'>
            <span>Date</span>
            <button>
              <SortIcon />
            </button>
          </div>
          <div className='action'>
            <span>Actions</span>
          </div>
        </div>

        {quotes?.map((item) => {
          return (
            <div className='table-item' key={item?.id}>
              <div className='id'>
                <span>{item?.jobcard_ref}</span>
              </div>
              <div className='total'>
                <span>₦{numberWithCommas(item?.total_markup || 0.0)}</span>
              </div>
              {/* <div
                className={`status ${
                  item?.status === "pending"
                    ? "pending"
                    : item?.status === "submitted"
                    ? "submitted"
                    : ""
                }`}
              >
                <span>{item?.status}</span>
              </div> */}
              <div className='date'>
                <span>
                  {format(new Date(item?.created_at), "do MMM, yyyy")}
                </span>
              </div>
              <div className='action'>
                <Link
                  to='/quote-details'
                  onClick={() => {
                    const selectedItem = { ...item };
                    const newLineItems = selectedItem.line_items_breakdown.map(
                      (t) => {
                        return { ...t, approved: false };
                      }
                    );

                    console.log(newLineItems);

                    selectedItem["line_items_breakdown"] = newLineItems;

                    console.log(selectedItem);

                    dispatch(setQuote(selectedItem));
                  }}
                >
                  View
                </Link>
              </div>
            </div>
          );
        })}

        {quotesMeta !== {} ? (
          <div className='bottom'>
            <div className='pagination'>
              <button onClick={fetchPrevPage}>
                <PrevIcon />
              </button>
              <span>
                {quotesMeta?.current_page} of {quotesMeta?.last_page}
              </span>
              <button onClick={fetchNextPage}>
                <NextIcon />
              </button>
            </div>

            <div className='row'>
              <span>Show:</span>
              <select name='rows' onChange={(e) => setRows(e.target.value)}>
                <option value='10' defaultChecked>
                  10 rows
                </option>
                <option value='15' defaultChecked>
                  15 rows
                </option>
                <option value='20' defaultChecked>
                  20 rows
                </option>
              </select>
            </div>
          </div>
        ) : null}
      </div>
    </View>
  );
};

export default Quotes;

const View = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background-color: ${({ theme }) => theme.grey_100};
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  margin: 0;

  .table-view {
    width: 100%;
    padding: 20px;
    background-color: ${({ theme }) => theme.white};
    box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
    border-radius: 4px;
    margin: 20px 0;

    @media ${device.phone} {
      width: 200%;
      overflow-x: scroll;
    }

    .header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .export {
        width: 120px;
        height: 35px;
        background-color: transparent;
        border: 1px solid ${({ theme }) => theme.grey_200};
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${({ theme }) => theme.grey_500};
        cursor: pointer;

        span {
          margin-left: 10px;
        }
      }

      .search-view {
        width: 50%;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        .filter-box {
          width: 70%;
          /* height: 100px; */
          background-color: ${(props) => props.theme.white};
          border-radius: 0 0 4px 4px;
          padding: 10px;
          position: absolute;
          top: 40px;
          right: 0;
          box-shadow: 0px 2px 9px -2px rgba(207, 207, 207, 0.49);
          -webkit-box-shadow: 0px 2px 9px -2px rgba(207, 207, 207, 0.49);
          -moz-box-shadow: 0px 2px 9px -2px rgba(207, 207, 207, 0.49);

          .head {
            width: 100%;
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            span {
              margin-left: 5px;
              font-size: 0.9rem;
            }
          }

          .input {
            width: 100%;
            margin-bottom: 10px;

            select {
              width: 100%;
              padding: 10px;
              background-color: transparent;
              outline: none;
              border-radius: 4px;
              border: 1px solid ${(props) => props.theme.grey_200};
            }
          }

          .cta {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 20px;

            button {
              background-color: ${(props) => props.theme.blue_600};
              border: none;
              outline: none;
              font-size: 0.8rem;
              font-weight: 600;
              color: ${(props) => props.theme.white};
              padding: 8px 20px;
              border-radius: 4px;
              cursor: pointer;
              width: 80px;
            }

            .clear {
              background-color: transparent;
              color: #000;
            }
          }
        }

        input {
          height: 100%;
          width: 90%;
          border: 1px solid ${({ theme }) => theme.grey_200};
          border-radius: 4px;
          outline: none;
          padding: 6px;
          margin-top: 3px;

          &::placeholder {
            color: ${({ theme }) => theme.grey_300};
            font-size: 0.9rem;
          }

          &:focus {
            border: 1px solid ${({ theme }) => theme.black};
          }
        }

        button {
          background-color: transparent;
          outline: none;
          border: none;
          cursor: pointer;
          height: 100%;
          width: 40px;
          border: 1px solid ${({ theme }) => theme.grey_200};
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
        }
      }
    }

    .table-head {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: ${({ theme }) => theme.grey_100};
      padding: 12px;
      margin-top: 20px;
      color: ${({ theme }) => theme.grey_500};

      div {
        flex: 1;
        display: flex;
        align-items: center;
        font-size: 0.75rem;
        font-weight: 600;

        button {
          background-color: transparent;
          padding: 0;
          outline: none;
          border: none;
          cursor: pointer;
          margin-left: 5px;
        }
      }

      .id {
        flex: 0.8;
      }

      .status {
        flex: 0.3;
        margin-right: 10px;
      }
      .date {
        flex: 0.7;
      }
      .total {
        flex: 0.5;
      }
      .action {
        flex: 0.5;
      }
    }

    .table-item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px;
      color: ${({ theme }) => theme.grey_700};

      div {
        flex: 1;
        display: flex;
        align-items: center;
        font-size: 0.875rem;
        font-weight: 400;

        button {
          background-color: transparent;
          padding: 0;
          outline: none;
          border: none;
          cursor: pointer;
          margin-left: 5px;
        }
      }

      .id {
        flex: 0.8;
      }

      .status {
        flex: 0.3;
        margin-right: 10px;
        align-items: center;
        justify-content: center;
        padding: 3px;
        border-radius: 4px;
        text-transform: capitalize;
      }
      .date {
        flex: 0.7;
      }
      .total {
        flex: 0.5;
      }
      .action {
        flex: 0.5;
      }

      .pending {
        color: ${({ theme }) => theme.blue_600};
        background-color: ${({ theme }) => theme.blue_50};
      }
      .submitted {
        color: ${({ theme }) => theme.green};
        background-color: ${({ theme }) => theme.green_50};
      }
    }

    .bottom {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;

      .pagination {
        width: 13%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media ${device.tablet} {
          width: 150px;
        }

        @media ${device.phone} {
          width: 20%;
        }

        button {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          border: 1px solid ${({ theme }) => theme.grey_200};
          outline: none;
          cursor: pointer;
          background-color: transparent;
        }
      }

      .row {
        width: 15%;
        display: flex;
        align-items: center;

        select {
          border: 1px solid ${({ theme }) => theme.grey_200};
          background-color: transparent;
          margin-left: 10px;
          padding: 5px;
          border-radius: 4px;
        }

        @media ${device.tablet} {
          width: 200px;
        }
        @media ${device.phone} {
          width: 25%;
        }
      }
    }
  }
`;
