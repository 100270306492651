import React from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import {formatAmount} from '../hooks/currencyFormatter';

import {
  Card as MuiCard,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  width: 100%;
  max-width: 100%;
`;

const Header = styled.div`
  width: 100%;
  padding-left: 0.3rem;
  height: 15%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
`;

const TableComponent = ({ rows, id, children, className, tableClass }) => {
  return (
    <Card mb={6} mt={6} className={className}>
      <Header>{children}</Header>

      <Paper>
        <TableWrapper>
          <Table id={id} className={tableClass}>
            <TableHead>
              <TableRow>
                <TableCell align="left">Date</TableCell>
                <TableCell align="left">Mileage</TableCell>
                <TableCell align="left">License No</TableCell>
                <TableCell align="left">Status</TableCell>
                <TableCell align="left">Vehicle</TableCell>
                <TableCell align="left">Vin</TableCell>
                <TableCell align="left">Amount</TableCell>
                <TableCell align="left">Job No</TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ padding: "0.5rem" }}>
              {rows ? (
                rows.map((row) => (
                  <TableRow style={{ width: "100%" }} key={row.id}>
                    <TableCell align="left">
                      {format(new Date(row.created_at), "do MMM, yyyy")}
                    </TableCell>
                    <TableCell align="left">{row.mileage_in}</TableCell>
                    <TableCell align="left">{row.vehicle.license_no}</TableCell>
                    <TableCell align="left">
                      <span
                        className={`status ${
                          row?.status === "Open"
                            ? "open"
                            : row?.status === "Approved"
                            ? "closed"
                            : row?.status === "Delivered"
                            ? "delivered"
                            : row?.status === "Awaiting Approval"
                            ? "awaiting"
                            : row?.status === "In Progress"
                            ? "progress"
                            : row?.status === "Job Not Done"
                            ? "not_done"
                            : ""
                        }`}
                      >
                        {row.status}
                      </span>
                    </TableCell>
                    <TableCell align="left">
                      {row?.vehicle?.year}, {row?.vehicle?.make}{" "}
                      {row?.vehicle?.model}
                    </TableCell>
                    <TableCell align="left">{row.vehicle.vin}</TableCell>
                    <TableCell align="left">{row.accepted_quote? formatAmount(row.accepted_quote.total_markup) : 0}</TableCell>
                    <TableCell align="left">{row.card_ref}</TableCell>
                    <TableCell align="left">
                      <Link
                        to={`/service-history/${row?.id}`}
                        style={{ textDecoration: "none" }}
                      >
                        View
                      </Link>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <>No recordes to show</>
              )}
            </TableBody>
          </Table>
        </TableWrapper>
      </Paper>
    </Card>
  );
};

export default TableComponent;
