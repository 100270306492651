import styled from "styled-components";
import { device } from "../constants/breakpoints";

export const View = styled.div`
  width: 100%;
  height: 100%;
  padding: 40px 30px;
  overflow-y: scroll;
  background-color: ${({ theme }) => theme.grey_100};
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media ${device.tablet} {
    padding: 40px;
    width: 100vw;
  }

  @media ${device.phone} {
    padding: 40px 20px;
    width: 100vw;
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;

    h2 {
      font-size: 1.25rem;
      color: ${({ theme }) => theme.grey_700};
      margin-top: 40px;
      font-weight: 400;
    }

    .vehicles {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
      scrollbar-width: none;
      -ms-overflow-style: none;
      scroll-snap-type: x mandatory;
      scroll-padding: 10%;
      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      @media ${device.phone} {
        justify-content: initial;
      }

      .vehicle {
        display: block;
        width: 23%;
        background-color: ${({ theme }) => theme.white};
        box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
        border-radius: 4px;
        padding: 15px;
        scroll-snap-align: start;
        min-height: 100px;
        min-height: 150px;

        @media ${device.phone} {
          width: 300px;
          margin-right: 20px;
        }

        span {
          color: ${({ theme }) => theme.grey_400};
          font-size: 0.875rem;
        }

        p {
          color: ${({ theme }) => theme.grey_500};
          font-size: 1rem;
        }

        h3 {
          color: ${({ theme }) => theme.grey_700};
          font-size: 1.2rem;
          font-weight: 500;
        }
      }
    }

    .table-row {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-top: 30px;

      @media ${device.tablet} {
        flex-direction: column;
        align-items: flex-start;
      }

      @media ${device.phone} {
        flex-direction: column;
        align-items: flex-start;
      }

      .jobs {
        width: 49%;
        min-height: 60vh;
        display: flex;
        flex-direction: column;
        background-color: ${({ theme }) => theme.white};
        box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
        padding: 20px;
        border-radius: 4px;

        @media ${device.tablet} {
          width: 100%;
          margin-bottom: 30px;
          min-height: 40vh;
        }

        @media ${device.phone} {
          width: 100%;
          margin-bottom: 30px;
        }

        .header {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          h3 {
            font-size: 1.25rem;
            font-weight: 400;
            color: ${({ theme }) => theme.grey_700};
          }

          a {
            color: ${({ theme }) => theme.grey_400};
          }
        }

        .table-head {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: ${({ theme }) => theme.grey_100};
          padding: 12px;
          margin-top: 20px;
          color: ${({ theme }) => theme.grey_500};

          div {
            flex: 1;
            display: flex;
            align-items: center;
            font-size: 0.75rem;
            font-weight: 600;

            button {
              background-color: transparent;
              padding: 0;
              outline: none;
              border: none;
              cursor: pointer;
              margin-left: 5px;
            }
          }

          .id {
            flex: 1.5;
          }

          .status {
            flex: 0.7;
            margin-right: 10px;
          }
          .date {
            flex: 0.7;
          }
        }

        .table-item {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 12px;
          color: ${({ theme }) => theme.grey_700};

          div {
            flex: 1;
            display: flex;
            align-items: center;
            font-size: 0.875rem;
            font-weight: 400;

            button {
              background-color: transparent;
              padding: 0;
              outline: none;
              border: none;
              cursor: pointer;
              margin-left: 5px;
            }
          }

          .id {
            flex: 1.5;
          }

          .status {
            flex: 0.7;
            margin-right: 10px;
            align-items: center;
            justify-content: center;
            padding: 3px;
            border-radius: 4px;
            text-transform: capitalize;
          }
          .date {
            flex: 0.7;
          }

          .open {
            color: ${({ theme }) => theme.blue_600};
            background-color: ${({ theme }) => theme.blue_50};
          }
          .closed {
            color: ${({ theme }) => theme.green};
            background-color: ${({ theme }) => theme.green_50};
          }
        }

        .empty {
          width: 100%;
          height: 200px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          margin-top: 20px;

          p {
            margin-top: 10px;
          }

          a {
            margin-top: 20px;
            color: ${({ theme }) => theme.black};
          }
        }
      }

      .subs {
        width: 49%;
        min-height: 60vh;
        display: flex;
        flex-direction: column;
        background-color: ${({ theme }) => theme.white};
        box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
        padding: 20px;
        border-radius: 4px;

        @media ${device.phone} {
          width: 100%;
        }

        .header {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          h3 {
            font-size: 1.25rem;
            font-weight: 400;
            color: ${({ theme }) => theme.grey_700};
          }

          a {
            color: ${({ theme }) => theme.grey_400};
          }
        }

        .empty {
          width: 100%;
          height: 200px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }
      }
    }
  }
`;
