import styled from "styled-components";
import { device } from "../constants/breakpoints";

export const View = styled.div`
  width: 100%;
  height: 100%;
  padding: 40px 30px;
  overflow-y: scroll;
  background-color: ${({ theme }) => theme.grey_100};
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media ${device.tablet} {
    width: 100vw;
    padding: 40px;
  }

  @media ${device.phone} {
    width: 100vw;
    padding: 40px 20px;
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;

    .table-view {
      width: 100%;
      margin-top: 50px;
      padding: 20px;
      background-color: ${({ theme }) => theme.white};
      box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
      border-radius: 4px;
      overflow-x: scroll;

      @media ${device.tablet} {
        padding: 12px;
        width: 200%;
      }

      @media ${device.phone} {
        padding: 12px;
        width: 300%;
      }

      .empty {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
      }

      .header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media ${device.phone} {
          flex-direction: column-reverse;
          align-items: flex-start;
          justify-content: flex-start;
          width: 300px;
        }

        .export {
          width: 120px;
          height: 35px;
          background-color: transparent;
          border: 1px solid ${({ theme }) => theme.grey_200};
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: ${({ theme }) => theme.grey_500};
          cursor: pointer;

          span {
            margin-left: 10px;
          }

          @media ${device.phone} {
            margin-bottom: 10px;
          }
        }

        .search-view {
          width: 50%;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;

          @media ${device.phone} {
            width: 100%;
          }

          .filter-box {
            width: 70%;
            background-color: ${(props) => props.theme.white};
            border-radius: 0 0 4px 4px;
            padding: 10px;
            position: absolute;
            top: 40px;
            right: 0;
            box-shadow: 0px 2px 9px -2px rgba(207, 207, 207, 0.49);
            -webkit-box-shadow: 0px 2px 9px -2px rgba(207, 207, 207, 0.49);
            -moz-box-shadow: 0px 2px 9px -2px rgba(207, 207, 207, 0.49);

            @media ${device.phone} {
              width: 100%;
              background-color: ${(props) => props.theme.white};
            }

            .head {
              width: 100%;
              display: flex;
              align-items: center;
              margin-bottom: 10px;

              span {
                margin-left: 5px;
                font-size: 0.9rem;
              }
            }

            .input {
              width: 100%;
              margin-bottom: 10px;

              select {
                width: 100%;
                padding: 10px;
                background-color: transparent;
                outline: none;
                border-radius: 4px;
                border: 1px solid ${(props) => props.theme.grey_200};
              }
            }

            .cta {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              margin-top: 20px;

              button {
                background-color: ${(props) => props.theme.blue};
                border: none;
                outline: none;
                font-size: 0.8rem;
                font-weight: 600;
                color: ${(props) => props.theme.white};
                padding: 8px 20px;
                border-radius: 4px;
                cursor: pointer;
                width: 80px;
              }

              .clear {
                background-color: transparent;
                color: #000;
              }
            }
          }

          input {
            height: 100%;
            width: 90%;
            border: 1px solid ${({ theme }) => theme.grey_200};
            border-radius: 4px;
            outline: none;
            padding: 6px;
            margin-top: 3px;

            &::placeholder {
              color: ${({ theme }) => theme.grey_300};
              font-size: 0.9rem;
            }

            &:focus {
              border: 1px solid ${({ theme }) => theme.black};
            }

            @media ${device.phone} {
              width: 80%;
            }
          }

          button {
            background-color: transparent;
            outline: none;
            border: none;
            cursor: pointer;
            height: 100%;
            width: 40px;
            border: 1px solid ${({ theme }) => theme.grey_200};
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
          }
        }
      }

      .table-head {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: ${({ theme }) => theme.grey_100};
        padding: 12px;
        margin-top: 20px;
        color: ${({ theme }) => theme.grey_500};

        div {
          flex: 1;
          display: flex;
          align-items: center;
          font-size: 0.75rem;
          font-weight: 600;

          button {
            background-color: transparent;
            padding: 0;
            outline: none;
            border: none;
            cursor: pointer;
            margin-left: 5px;
          }
        }

        .id {
          flex: 0.8;
        }

        .status {
          flex: 0.5;
          margin-right: 10px;
        }
        .date {
          flex: 0.7;
        }
        .license {
          flex: 0.7;
        }
        .action {
          flex: 0.3;
        }
      }

      .table-item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 12px;
        color: ${({ theme }) => theme.grey_700};
         
      
        div {
          flex: 1;
          display: flex;
          align-items: center;
          font-size: 0.875rem;
          font-weight: 400;

          button {
            background-color: transparent;
            padding: 0;
            outline: none;
            border: none;
            cursor: pointer;
            margin-left: 5px;
          }
        }


        .status {
          flex: 0.5;
          margin-right: 10px;
          align-items: center;
          justify-content: center;
          padding: 3px;
          border-radius: 4px;
        }
        .date {
          flex: 0.7;
        }
        .license {
          flex: 0.7;
        }
        .action {
          flex: 0.3;
        } 

        .open {
          color: ${({ theme }) => theme.blue_600};
          background-color: ${({ theme }) => theme.blue_50};
        }
        .closed {
          color: ${({ theme }) => theme.green};
          background-color: ${({ theme }) => theme.green_50};
        }
      }

      .bottom {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;

        .pagination {
          width: 13%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          button {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            border: 1px solid ${({ theme }) => theme.grey_200};
            outline: none;
            cursor: pointer;
            background-color: transparent;
          }
        }

        .row {
          width: 15%;
          display: flex;
          align-items: center;

          select {
            border: 1px solid ${({ theme }) => theme.grey_200};
            background-color: transparent;
            margin-left: 10px;
            padding: 5px;
            border-radius: 4px;
          }
        }
      }
    }
  }
`;
