import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Loader from "react-loader-spinner";
import { toast } from "react-hot-toast";
import Layout from "../components/Layout";
import InputModal from "../components/shared/InputModal";
import ModalLoader from "../components/shared/Loader";
import PageHeader from "../components/shared/PageHeader";
import {
  addVehicle,
  fetchVehicles,
  setVehicle,
  vehicleSelector,
} from "../redux/features/vehicleSlice";
import { View } from "../styles/vehicles.styles";
import { ReactComponent as CloseIcon } from "../assets/icons/close-icon.svg";
import {
  fetchUserSubscriptions,
  setView,
} from "../redux/features/subscriptionSlice";

const schema = yup
  .object({
    vin: yup
      .string()
      .required("VIN is required")
      .min(17, "The VIN must be 17 characters."),
    make: yup.string().required("Vehicle make is required"),
    model: yup.string().required("Vehicle model is required"),
    year: yup.string().required("Vehicle year is required"),
    number_of_cylinders: yup
      .number()
      .required("Number of cylinders is required"),
  })
  .required();

const Subscriptions = () => {
  const dispatch = useDispatch();
  const { vehicles, loading, error, message, vehicle_brands } =
    useSelector(vehicleSelector);
  const [showModal, setShowModal] = useState(false);
  const [models, setModels] = useState([]);
  const [make, setMake] = useState("");
  const [vin, setVin] = useState("");
  const [license, setLicense] = useState("");

  useEffect(() => {
    setShowModal(false);
    dispatch(fetchVehicles());
    dispatch(fetchUserSubscriptions());
  }, [dispatch]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    setShowModal(false);
    const form = { ...data, make };
    dispatch(addVehicle(form));
  };

  useEffect(() => {
    error && toast.error(error);
  }, [error]);

  useEffect(() => {
    if (message) {
      toast.success(message);
    }

    // eslint-disable-next-line
  }, [message]);

  return (
    <Layout>
      <View>
        <PageHeader title='Subscriptions' />

        <div className='content'>
          <div className='add-view'>
            <button onClick={() => setShowModal(true)}>Add Vehicle</button>
          </div>

          <div className='vehicles'>
            {vehicles?.map((item) => {
              return (
                <div className='item' key={item?.id}>
                  {item?.subscription !== null ? (
                    <span>{item?.subscription?.subscription_type?.name}</span>
                  ) : (
                    <span>No Subscription</span>
                  )}
                  <h4>
                    {item?.year}, {item?.make} {item?.model}
                  </h4>
                  <p>
                    {item?.vin} <div className='gap'></div>{" "}
                    {item?.license_no || "-"}
                  </p>

                  <div className='cta'>
                    {item?.subscription ? (
                      <Link
                        to={`/subscription-details`}
                        onClick={() => {
                          if (item?.subscription) {
                            dispatch(setView(0));
                            dispatch(setVehicle(item));
                          } else {
                            dispatch(setView(1));
                            dispatch(setVehicle(item));
                          }
                        }}
                      >
                        View Details
                      </Link>
                    ) : (
                      <Link
                        className='subscribe'
                        to={`/subscription-details`}
                        onClick={() => {
                          if (item?.subscription) {
                            dispatch(setView(0));
                            dispatch(setVehicle(item));
                          } else {
                            dispatch(setView(1));
                            dispatch(setVehicle(item));
                          }
                        }}
                      >
                        Subscribe Now
                      </Link>
                    )}
                  </div>
                </div>
              );
            })}
          </div>

          {vehicles?.length < 1 ? (
            <div className='empty'>
              <p>No result found</p>
            </div>
          ) : null}
        </div>
      </View>

      <InputModal showModal={showModal}>
        <header>
          <h3>Add Vehicle</h3>

          <button onClick={() => setShowModal(false)}>
            <CloseIcon />
          </button>
        </header>

        <form onSubmit={handleSubmit(onSubmit)}>
          {/* ===== VIN ==== */}
          <div className='input-view'>
            <label htmlFor='vin'>
              VIN/Chasis number <span>*</span>
            </label>
            <input
              type='text'
              placeholder='Enter VIN'
              {...register("vin")}
              onChange={(e) => setVin(e.target.value.toUpperCase())}
              value={vin}
            />

            <p>{errors.vin?.message}</p>
          </div>

          <div className='row'>
            {/* ===== Make ==== */}
            <div className='input-view'>
              <label htmlFor='make'>
                Make <span>*</span>
              </label>
              <select
                name='make'
                {...register("make")}
                onChange={(e) => {
                  setModels(JSON.parse(e.target.value));
                  setMake(JSON.parse(e.target.value).name);
                }}
              >
                <option value='' defaultChecked>
                  Choose
                </option>
                {vehicle_brands?.map((item) => {
                  return (
                    <option key={item.id} value={JSON.stringify(item)}>
                      {item?.name}
                    </option>
                  );
                })}
              </select>
              <p>{errors.make?.message}</p>
            </div>

            <div className='input-view'>
              <label htmlFor='model'>
                Model <span>*</span>
              </label>
              <select name='make' {...register("model")}>
                <option value='' defaultChecked>
                  Choose
                </option>
                {models?.models?.map((item) => {
                  return (
                    <option key={item?.id} value={item?.name}>
                      {item?.name}
                    </option>
                  );
                })}
              </select>
              <p>{errors.model?.message}</p>
            </div>
          </div>

          <div className='row'>
            {/* ===== Year ==== */}
            <div className='input-view'>
              <label htmlFor='year'>
                Year <span>*</span>
              </label>
              <input
                type='text'
                placeholder='EnterYear'
                {...register("year")}
              />

              <p>{errors.year?.message}</p>
            </div>

            {/* ===== Cylinder ==== */}
            <div className='input-view'>
              <label htmlFor='cylinder'>
                No of Cylinders <span>*</span>
              </label>
              <select name='cylinder' {...register("number_of_cylinders")}>
                <option value={0} defaultChecked>
                  Choose
                </option>
                <option value={4}>4</option>
                <option value={6}>6</option>
                <option value={8}>8</option>
                <option value={10}>10</option>
                <option value={12}>12</option>
              </select>

              <p>{errors.number_of_cylinders?.message}</p>
            </div>
          </div>

          {/* ===== License Number ==== */}
          <div className='input-view'>
            <label htmlFor='license_no'>License no</label>
            <input
              type='text'
              placeholder='Enter license no'
              {...register("license_no")}
              onChange={(e) => setLicense(e.target.value.toUpperCase())}
              value={license}
            />

            <p>{errors.license_no?.message}</p>
          </div>

          <div className='cta'>
            <button type='submit'>
              {loading ? (
                <Loader
                  type='Oval'
                  color='#FFF'
                  height={25}
                  width={25}
                  timeout={10000}
                />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </form>
      </InputModal>

      <ModalLoader showModal={loading} />
    </Layout>
  );
};

export default Subscriptions;
