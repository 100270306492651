import styled from "styled-components";
import { device } from "../constants/breakpoints";

export const View = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background-color: ${({ theme }) => theme.grey_100};
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media ${device.tablet} {
    width: 100vw;
    /* padding: 20px; */
  }
  @media ${device.phone} {
    width: 100vw;
    /* padding: 20px; */
  }

  .header-view {
    width: 80%;
    display: flex;
    flex-direction: column;
    top: 60px;
    position: fixed;
    z-index: 9000;
    background-color: ${({ theme }) => theme.white};
    border-bottom: 1px solid ${({ theme }) => theme.grey_200};
    padding: 0 30px;
    padding-top: 20px;
    max-height: 150px;

    @media ${device.tablet} {
      width: 100%;
      padding: 0 20px;
      padding-top: 20px;
    }
    @media ${device.phone} {
      width: 100%;
      padding: 0 20px;
      padding-top: 20px;
    }

    .vehicle-view {
      width: 100%;
      display: flex;

      select {
        height: 45px;
        border: 1px solid ${({ theme }) => theme.grey_200};
        border-radius: 4px;
        outline: none;
        padding: 10px;
        margin-top: 3px;
        background-color: transparent;

        @media ${device.phone} {
          width: 100%;
        }

        &::placeholder {
          color: ${({ theme }) => theme.grey_300};
          font-size: 0.9rem;
        }

        &:focus {
          border: 1px solid ${({ theme }) => theme.black};
        }
      }
    }

    .tab-view {
      width: 100%;
      display: flex;
      margin-top: 30px;

      button {
        padding: 6px 0;
        margin-right: 20px;
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        font-weight: 600;
        color: ${({ theme }) => theme.grey_400};
        font-size: 0.9rem;
        transition: all 0.2s ease-in-out;
        border-bottom: 2px solid ${({ theme }) => theme.white};
      }

      .active {
        color: ${({ theme }) => theme.blue_600};
        border-bottom: 2px solid ${({ theme }) => theme.blue_600};
        transition: all 0.2s ease-in-out;
      }
    }
  }

  .content {
    width: 100%;
    height: 100%;
    padding: 0 30px;
    padding-top: 220px;

    @media ${device.phone} {
      padding: 0 20px;
      padding-top: 200px;
    }
  }
`;
