import React, { useEffect } from "react";
import { GlobalStyle } from "./styles/globalStyles";
import { ThemeProvider } from "styled-components";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import PrivateRoutes from "./pages/ProtectedRoutes";
import { Toaster } from "react-hot-toast";
import { routes } from "./constants/routes";
import { theme } from "./constants/theme";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import NotFound from "./pages/404";
import ResetPassword from "./pages/ResetPassword";
import ReactGA from "react-ga";

ReactGA.initialize("G-WYVNQBZ95Q");

const App = () => {
  // Google Analytics
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Toaster
        containerStyle={{
          top: 0,
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          margin: 0,
          padding: 0,
          left: 0,
          borderRadius: 0,
        }}
        toastOptions={{
          className: "toast",
          style: {
            padding: "10px",
            color: "#000",
            minWidth: "100%",
            fontSize: "14px",
            fontWeight: "600",
            margin: 0,
            borderRadius: 0,
          },
          success: {
            style: {
              background: "#E7F9EA",
              color: "#3BC279",
            },
          },
          error: {
            style: {
              background: "#FFE4E6",
              color: "#e52f22",
            },
          },
        }}
        position='top-right'
      />

      <Router>
        <Switch>
          <Route exact path='/'>
            <Redirect to='/dashboard' />
          </Route>

          <Route exact path='/login'>
            <Login />
          </Route>
          <Route exact path='/signup'>
            <Signup />
          </Route>
          <Route exact path='/reset-password'>
            <ResetPassword />
          </Route>
          {/* ======== PROTECTED ROUTES ========== */}

          <Route>
            {routes.map((route) => (
              <PrivateRoutes
                key={route.path}
                exact
                path={route.path}
                component={route.component}
              />
            ))}
          </Route>

          {/* ====== 404 ======= */}
          <Route path='*'>
            <NotFound />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

export default App;
