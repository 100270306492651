export const theme = {
  white: "#FFFFFF",
  black: "#000000",
  grey_100: "#F9FAFB",
  grey_200: "#E5E7EB",
  grey_300: "#D1D5DB",
  grey_400: "#9CA3AF",
  grey_500: "#6B7280",
  grey_700: "#374151",
  grey_900: "#171717",
  blue_600: "#5048E5",
  red_500: "#F43F5E",
  blue_50: "#F0F3FF",
  blue: "#007bff",
  green: "#65B98F",
  green_50: "#E5F9EE",
  yellow: "#EFEF66",
  amber: "#FFBF00",
  red: "#DC143C",
};
