import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import ReactDom from "react-dom";
import Loader from "react-loader-spinner";
// import Lottie from "react-lottie";
// import lottieLoader from "../../lotties/loader.json";

// const lottieOptions = {
//   loop: true,
//   autoplay: true,
//   animationData: lottieLoader,
//   rendererSettings: {
//     preserveAspectRatio: "xMidYMid slice",
//   },
// };

const backdrop = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

const ModalOverlay = (props) => {
  const content = (
    <Content>
      {/* <Lottie options={lottieOptions} height={200} width={200} /> */}
      <Loader type='Oval' color='#fff' height={60} width={60} />
    </Content>
  );

  return ReactDom.createPortal(
    content,
    document.getElementById("modal-loader")
  );
};

const ModalLoader = (props) => {
  return (
    <AnimatePresence exitBeforeEnter>
      {props.showModal && (
        <ModalView
          className='backdrop'
          variants={backdrop}
          initial='hidden'
          animate='visible'
        >
          <ModalOverlay {...props} />
        </ModalView>
      )}
    </AnimatePresence>
  );
};

export default ModalLoader;

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 10000;
`;

const Content = styled.div`
  background-color: transparent;
  z-index: 20000;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
