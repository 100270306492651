import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../../helpers/api";
import { logoutUser } from "./userSlice";

const initialState = {
  loading: false,
  error: null,
  message: null,
  vehicles: [],
  dashboardVehicles: [],
  vehicle: {},
  vehicle_brands: [],
};

const vehicleSlice = createSlice({
  name: "vehicles",
  initialState,
  reducers: {
    fetch: (state) => {
      state.loading = true;
      state.message = null;
      state.error = null;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    setMessage: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
    },
    clear: (state) => {
      state.loading = false;
      state.error = null;
      state.message = null;
    },
    setVehicles: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.vehicles = payload;
    },
    setVehicle: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.vehicle = payload;
    },
    setDashVehicles: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.dashboardVehicles = payload;
    },
    setVehicleBrands: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.vehicle_brands = payload;
    },
  },
});

export const {
  setError,
  fetch,
  setMessage,
  clear,
  setVehicles,
  setDashVehicles,
  setVehicle,
  setVehicleBrands,
} = vehicleSlice.actions;
export default vehicleSlice.reducer;
export const vehicleSelector = (state) => state.vehicles;

// Fetch Vehicles
export function fetchVehicles() {
  return async (dispatch) => {
    dispatch(fetch());

    try {
      const res = await api.get("/vehicles");
      dispatch(setVehicles(res.data.data));
      dispatch(setDashVehicles(res.data.data.slice(0, 4)));
    } catch (error) {
      if (error?.response?.status === 500) {
        dispatch(setError("Something went wrong, please try again"));
      } else {
        dispatch(setError(error?.response?.data?.message));
      }
      dispatch(clear());
      if (error?.response?.status === 401) {
        dispatch(logoutUser());
      }
    }
  };
}

// Fetch Vehicles Brands
export function fetchVehicleBrands() {
  return async (dispatch) => {
    dispatch(fetch());

    try {
      const res = await axios.get(
        "https://platform.fixit45.com/api/vehicle_brands"
      );
      dispatch(setVehicleBrands(res.data.data));
    } catch (error) {
      if (error?.response?.status === 500) {
        dispatch(setError("Something went wrong, please try again"));
      } else {
        dispatch(setError(error?.response?.data?.message));
      }
      dispatch(clear());
      if (error?.response?.status === 401) {
        dispatch(logoutUser());
      }
    }
  };
}

// Fetch Vehicle
export function fetchVehicle(id) {
  return async (dispatch) => {
    dispatch(fetch());

    try {
      const res = await api.get(`/vehicles/${id}`);
      dispatch(setVehicle(res.data.data));
    } catch (error) {
      if (error?.response?.status === 500) {
        dispatch(setError("Something went wrong, please try again"));
      } else {
        dispatch(setError(error?.response?.data?.message));
      }
      dispatch(clear());
      if (error?.response?.status === 401) {
        dispatch(logoutUser());
      }
    }
  };
}

// Add a new vehicle
export function addVehicle(data) {
  return async (dispatch) => {
    dispatch(fetch());

    try {
      await api.post("/vehicles", data);
      dispatch(fetchVehicles());
      dispatch(setMessage("Vehicle added successfully"));
    } catch (error) {
      if (error?.response?.status === 500) {
        dispatch(setError("Something went wrong, please try again"));
      } else {
        const errors = error?.response?.data?.errors;
        Object.keys(errors).map((error) =>
          dispatch(setError(errors[error][0]))
        );
      }
      dispatch(clear());
      if (error?.response?.status === 401) {
        dispatch(logoutUser());
      }
    }
  };
}

//Update vehicle
export function updateVehicle(id, data) {
  return async (dispatch) => {
    dispatch(fetch());

    try {
      await api.put(`/vehicles/${id}`, data);
      dispatch(fetchVehicles());
      dispatch(setMessage("Vehicle updated successfully"));
    } catch (error) {
      if (error?.response?.status === 500) {
        dispatch(setError("Something went wrong, please try again"));
      } else {
        const errors = error?.response?.data?.errors;
        Object.keys(errors).map((error) =>
          dispatch(setError(errors[error][0]))
        );
      }
      dispatch(clear());
      if (error?.response?.status === 401) {
        dispatch(logoutUser());
      }
    }
  };
}
