import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import Loader from "react-loader-spinner";
import { useSelector } from "react-redux";
import { updatePassword, userSelector } from "../../redux/features/userSlice";
import { device } from "../../constants/breakpoints";
import { useDispatch } from "react-redux";
import { ReactComponent as ShowIcon } from "../../assets/icons/show-icon.svg";
import { ReactComponent as HideIcon } from "../../assets/icons/hide-icon.svg";

const AccountSettings = () => {
  const dispatch = useDispatch();
  const { loading, message, error } = useSelector(userSelector);
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    error && toast.error(error);
  }, [error]);

  useEffect(() => {
    message && toast.success(message);
  }, [message]);

  const onSubmit = (data) => {
    dispatch(updatePassword(data));
  };

  return (
    <View>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3>Change Password</h3>
        <div className='input-view'>
          <label htmlFor='email'>Current Password</label>
          <input
            type={showPassword ? "text" : "password"}
            placeholder='Enter current password'
            {...register("current_password")}
          />
          {showPassword ? (
            <button
              type='button'
              onClick={() => setShowPassword(!showPassword)}
            >
              <HideIcon />
            </button>
          ) : (
            <button
              type='button'
              onClick={() => setShowPassword(!showPassword)}
            >
              <ShowIcon />
            </button>
          )}
          <p>{errors.current_password?.message}</p>
        </div>

        <div className='input-view'>
          <label htmlFor='email'>New Password</label>
          <input
            type={showPassword ? "text" : "password"}
            placeholder='Enter new password'
            {...register("new_password")}
          />
          {showPassword ? (
            <button
              type='button'
              onClick={() => setShowPassword(!showPassword)}
            >
              <HideIcon />
            </button>
          ) : (
            <button
              type='button'
              onClick={() => setShowPassword(!showPassword)}
            >
              <ShowIcon />
            </button>
          )}
          <p>{errors.new_password?.message}</p>
        </div>

        <div className='cta'>
          <button type='submit' className='btn'>
            {loading ? (
              <Loader
                type='Oval'
                color='#FFF'
                height={35}
                width={35}
                timeout={10000}
              />
            ) : (
              "Save Changes"
            )}
          </button>
        </div>
      </form>
    </View>
  );
};

export default AccountSettings;

const View = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background-color: ${({ theme }) => theme.grey_100};
  scrollbar-width: none;
  -ms-overflow-style: none;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  &::-webkit-scrollbar {
    display: none;
  }

  margin: 0;

  form {
    width: 60%;
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.white};
    padding: 20px;

    @media ${device.phone} {
      width: 100%;
    }

    h3 {
      margin-bottom: 20px;
      font-size: 1.25rem;
      font-weight: 500;
    }

    .input-view {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      position: relative;

      @media ${device.phone} {
        width: 100%;
      }

      label {
        font-weight: 500;
        color: ${({ theme }) => theme.grey_500};
        font-size: 0.875rem;
      }

      p {
        font-weight: 400;
        color: ${({ theme }) => theme.red_500};
        font-size: 0.875rem;
      }

      input,
      select {
        height: 45px;
        border: 1px solid ${({ theme }) => theme.grey_200};
        border-radius: 4px;
        outline: none;
        padding: 10px;
        margin-top: 3px;
        background-color: transparent;

        &::placeholder {
          color: ${({ theme }) => theme.grey_300};
          font-size: 0.9rem;
        }

        &:focus {
          border: 1px solid ${({ theme }) => theme.black};
        }
      }

      button {
        position: absolute;
        top: 32px;
        right: 10px;
        background: transparent;
        border: none;
        outline: none;
        cursor: pointer;
      }
    }

    .cta {
      button {
        background: ${({ theme }) => theme.blue};
        border: none;
        outline: none;
        color: ${({ theme }) => theme.white};
        padding: 0;
        cursor: pointer;
        border-radius: 4px;
        margin-top: 30px;
        height: 45px;
        width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media ${device.phone} {
          width: 100%;
        }
      }
    }
  }
`;
