import React, { useState } from "react";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";

import { ReactComponent as SettingsIcon } from "../../assets/icons/settings-icon.svg";
import { ReactComponent as NotifIcon } from "../../assets/icons/bell-icon.svg";
import { ReactComponent as MenuIcon } from "../../assets/icons/menu-icon.svg";
import { device } from "../../constants/breakpoints";
import MobileNavigator from "./MobileNavigator";
import { Link } from "react-router-dom";

const PageHeader = ({ title }) => {
  const [openNav, setOpenNav] = useState(false);
  return (
    <>
      <MobileNavigator openNav={openNav} setOpenNav={setOpenNav} />
      <View>
        <div className='icon-view'>
          <button className='icon' onClick={() => setOpenNav(true)}>
            <MenuIcon />
          </button>
          <span className='title'>{title}</span>
        </div>
        <div className='share-view'>
          <button className='notification' data-tip data-for='notifTip'>
            <NotifIcon />
            <div className='notif-count'></div>
          </button>
          <ReactTooltip id='notifTip' place='bottom' effect='solid'>
            Notifications
          </ReactTooltip>

          <Link to='/settings' data-tip data-for='shareTip'>
            <button className='notification'>
              <SettingsIcon />
            </button>
          </Link>
          <ReactTooltip id='shareTip' place='bottom' effect='solid'>
            Settings
          </ReactTooltip>
        </div>
      </View>
    </>
  );
};

export default PageHeader;

const View = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  background-color: ${({ theme }) => theme.white};
  position: fixed;
  top: 0;
  right: 0px;
  height: 60px;
  z-index: 9500;
  padding: 0 30px;
  border-bottom: 1px solid ${({ theme }) => theme.grey_200};

  @media ${device.tablet} {
    padding: 0 40px;
    width: 100%;
  }

  @media ${device.phone} {
    padding: 0 20px;
    width: 100%;
  }

  .icon-view {
    display: flex;
    align-items: center;
    .icon {
      display: none;
      background-color: transparent;
      border: none;
      padding: 0;
      outline: none;
      cursor: pointer;
      margin-right: 10px;

      @media ${device.tablet} {
        display: block;
      }
      @media ${device.phone} {
        display: block;
      }
    }

    .title {
      font-weight: 400;
      font-size: 1.1rem;
      color: ${({ theme }) => theme.grey_700};

      @media ${device.phone} {
        font-size: 1rem;
      }
    }
  }

  .share-view {
    width: 100px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    justify-content: space-between;

    @media ${device.phone} {
      width: 100px;
    }

    .logo {
      display: flex;
      height: 40px;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      border: 1px solid ${({ theme }) => theme.grey_400};
      border-radius: 35px;
      padding: 0 5px;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: ${({ theme }) => theme.grey_400};
      }

      .avatar {
        width: 35px;
        border-radius: 50%;
        margin-right: 10px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 50%;
        }
      }

      .store-name {
        width: 65%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        span {
          font-size: 0.8rem;
          font-weight: 400;
        }

        img {
          width: 12px;
          height: 10px;
          object-fit: contain;
          margin-left: 8px;
        }
      }
    }

    button {
      padding: 0;
      background-color: transparent;
      outline: none;
      cursor: pointer;
      border: 1px solid ${({ theme }) => theme.grey_200};
      height: 40px;
      width: 40px;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      display: flex;
      position: relative;
    }

    .share {
      height: 30px;
      width: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${({ theme }) => theme.blue_600};
      border-radius: 4px;
      color: ${({ theme }) => theme.white};
      font-size: 0.8rem;
      font-weight: 700;
    }

    .notification {
      position: relative;

      .notif-count {
        position: absolute;
        top: 0px;
        right: 3px;
        background-color: ${({ theme }) => theme.blue_600};
        width: 8px;
        height: 8px;
        border-radius: 50%;
        color: ${({ theme }) => theme.white};
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          font-weight: 700;
          font-size: 0.9rem;
        }
      }
    }
  }
`;
