import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Layout from "../components/Layout";
import ModalLoader from "../components/shared/Loader";
import PageHeader from "../components/shared/PageHeader";
import { fetchVehicle, vehicleSelector } from "../redux/features/vehicleSlice";
import { View } from "../styles/vehicle.styles";

const Vehicle = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading } = useSelector(vehicleSelector);

  useEffect(() => {
    dispatch(fetchVehicle(id));
  }, [dispatch, id]);

  return (
    <Layout>
      <View>
        <PageHeader title='Vehicle' />
      </View>

      <ModalLoader loading={loading} />
    </Layout>
  );
};

export default Vehicle;
