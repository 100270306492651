import React, { useState } from "react";
import Layout from "../components/Layout";
import AccountSettings from "../components/settings/AccountSettings";
import Profile from "../components/settings/Profile";
import { View } from "../styles/settings.styles";
import PageHeader from "../components/shared/PageHeader";

const Settings = () => {
  const [activeView, setActiveView] = useState(0);
  return (
    <Layout>
      <View>
        <PageHeader title='Settings' />
        {/* =====TAB VIEW ==== */}
        <div className='tab-view'>
          <button
            onClick={() => setActiveView(0)}
            className={activeView === 0 ? "active" : ""}
          >
            My Profile
          </button>
          <button
            onClick={() => setActiveView(1)}
            className={activeView === 1 ? "active" : ""}
          >
            Account Settings
          </button>
        </div>

        <div className='content'>
          {activeView === 0 && <Profile />}
          {activeView === 1 && <AccountSettings />}
        </div>
      </View>
    </Layout>
  );
};

export default Settings;
