import { createSlice } from "@reduxjs/toolkit";
import api from "../../helpers/api";
import { logoutUser } from "./userSlice";

const initialState = {
  loading: false,
  error: null,
  message: null,
  bookings: [],
  booking: {},
  bookingsMeta: {},
  workshops: [],
};

const bookingSlice = createSlice({
  name: "bookings",
  initialState,
  reducers: {
    fetch: (state) => {
      state.loading = true;
      state.message = null;
      state.error = null;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    setMessage: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
    },
    clear: (state) => {
      state.loading = false;
      state.error = null;
      state.message = null;
    },
    setBookings: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.bookings = payload;
    },
    setBookingsMeta: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.bookingsMeta = payload;
    },
    setBooking: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.booking = payload;
    },
    setWorkshops: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.workshops = payload;
    },
  },
});

export const {
  setError,
  fetch,
  setMessage,
  clear,
  setBooking,
  setBookings,
  setBookingsMeta,
  setWorkshops,
} = bookingSlice.actions;
export default bookingSlice.reducer;
export const bookingSelector = (state) => state.bookings;

/**
 * Submit a booking.
 *
 */
export function submitBooking(data) {
  return async (dispatch) => {
    dispatch(fetch());

    try {
      await api.post(`/bookings`, data);
      dispatch(setMessage("Your booking was successful"));
    } catch (error) {
      if (error?.response?.status === 500) {
        dispatch(setError("Something went wrong, please try again"));
      } else {
        dispatch(setError(error?.response?.data?.message));
      }
      dispatch(clear());
      if (error?.response?.status === 401) {
        dispatch(logoutUser());
      }
    }
  };
}

/**
 * We fetch the bookings from the API, and then we set the bookings in the store.
 */
export function fetchBookings(page, rows) {
  return async (dispatch) => {
    dispatch(fetch());

    try {
      const res = await api.get(`/bookings?per_page=${rows}&page=${page}`);
      dispatch(setBookings(res.data.data));
      dispatch(setBookingsMeta(res.data.meta));
    } catch (error) {
      if (error?.response?.status === 500) {
        dispatch(setError("Something went wrong, please try again"));
      } else {
        dispatch(setError(error?.response?.data?.message));
      }
      dispatch(clear());
      if (error?.response?.status === 401) {
        dispatch(logoutUser());
      }
    }
  };
}

/**
 * We fetch the booking with the given id, and then we set the booking in the state.
 */
export function fetchBooking(id) {
  return async (dispatch) => {
    dispatch(fetch());

    try {
      const res = await api.get(`/bookings/${id}`);
      dispatch(setBooking(res.data.data));
    } catch (error) {
      if (error?.response?.status === 500) {
        dispatch(setError("Something went wrong, please try again"));
      } else {
        dispatch(setError(error?.response?.data?.message));
      }
      dispatch(clear());
      if (error?.response?.status === 401) {
        dispatch(logoutUser());
      }
    }
  };
}

// Fetch workshops
export function fetchWorkshops(id) {
  return async (dispatch) => {
    dispatch(fetch());

    try {
      const res = await api.get(`/workshops`);
      dispatch(setWorkshops(res.data.data));
    } catch (error) {
      if (error?.response?.status === 500) {
        dispatch(setError("Something went wrong, please try again"));
      } else {
        dispatch(setError(error?.response?.data?.message));
      }
      dispatch(clear());
      if (error?.response?.status === 401) {
        dispatch(logoutUser());
      }
    }
  };
}

/**
 * We filter the bookings from the API, and then we dispatch the bookings to the reducer.
 */
export function filterBookings(date, booking_date) {
  return async (dispatch) => {
    dispatch(fetch());

    try {
      const res = await api.get(
        `/bookings?date=${date}&booking_date=${booking_date}`
      );
      dispatch(setBookings(res.data.data));
      // dispatch(setBookingsMeta({}));
    } catch (error) {
      if (error?.response?.status === 500) {
        dispatch(setError("Something went wrong, please try again"));
      } else {
        dispatch(setError(error?.response?.data?.message));
      }
    }
  };
}

/**
 * This function will search for a booking from the API and dispatch the bookings to the reducer.
 */
export function searchBookings(search) {
  return async (dispatch) => {
    dispatch(fetch());

    try {
      const res = await api.get(`/bookings?search=${search}`);
      dispatch(setBookings(res.data.data));
      // dispatch(setBookingsMeta({}));
    } catch (error) {
      if (error?.response?.status === 500) {
        dispatch(setError("Something went wrong, please try again"));
      } else {
        dispatch(setError(error?.response?.data?.message));
      }
    }
  };
}

export function searchWorkshops(search) {
  return async (dispatch) => {
    dispatch(fetch());

    try {
      const res = await api.get(`/workshops?search=${search}`);
      dispatch(setWorkshops(res.data.data));
    } catch (error) {
      if (error?.response?.status === 500) {
        dispatch(setError("Something went wrong, please try again"));
      } else {
        dispatch(setError(error?.response?.data?.message));
      }
    }
  };
}
