import styled from "styled-components";
import { device } from "../constants/breakpoints";

export const View = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px;
  padding-top: 60px;
  overflow-y: scroll;
  background-color: ${({ theme }) => theme.grey_100};
  -webkit-scrollbar {
    width: 0 !important;
  }
  overflow: -moz-scrollbars-none;

  @media ${device.tablet} {
    width: 100vw;
    padding: 60px 40px;
  }

  @media ${device.phone} {
    width: 100vw;
    padding: 40px 20px;
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    @media ${device.phone} {
      margin-top: 40px;
    }

    .empty {
      width: 100%;
      height: 50vh;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 50px;
    }

    .add-view {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button {
        width: 120px;
        height: 35px;
        background-color: ${({ theme }) => theme.blue};
        color: ${({ theme }) => theme.white};
        border-radius: 4px;
        border: none;
        outline: none;
        cursor: pointer;
      }
    }

    .vehicles {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 20px;

      .item {
        width: 32%;
        background-color: ${({ theme }) => theme.white};
        border-radius: 4px;
        padding: 15px;
        margin-bottom: 20px;
        min-height: 230px;
        position: relative;

        @media ${device.phone} {
          width: 100%;
        }

        .edit {
          width: 40px;
          height: 40px;
          background-color: transparent;
          border: none;
          border-radius: 4px;
          border: none;
          color: ${({ theme }) => theme.grey_500};
          outline: none;
          cursor: pointer;
          position: absolute;
          top: 10px;
          right: 10px;
        }

        h4 {
          margin-top: 15px;
          margin-bottom: 8px;
          font-size: 1.4rem;
          font-weight: 500;
        }

        span,
        p {
          color: ${({ theme }) => theme.grey_400};
        }
        p {
          font-size: 0.875rem;
          display: flex;
          align-items: center;
          .gap {
            width: 2px;
            height: 20px;
            background-color: ${({ theme }) => theme.grey_400};
            margin: 0 5px;
          }
        }

        .cta {
          width: 100%;
          display: flex;
          flex-direction: column;
          a {
            margin-top: 20px;
            width: 100%;
            height: 40px;
            background-color: transparent;
            border: none;
            border-radius: 4px;
            border: 1px solid ${({ theme }) => theme.blue};
            color: ${({ theme }) => theme.blue};
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
          }

          .subscribe {
            margin-top: 20px;
            width: 100%;
            height: 40px;
            background-color: ${({ theme }) => theme.blue};
            border: none;
            border-radius: 4px;
            border: none;
            color: ${({ theme }) => theme.white};
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
          }
        }
      }
    }
  }
`;
