import { createSlice } from "@reduxjs/toolkit";
import api from "../../helpers/api";
import { logoutUser } from "./userSlice";

const initialState = {
  loading: false,
  error: null,
  message: null,
  subscriptions: [],
  subscription: {},
  userSubscriptions: [],
  currentView: 0,
  vehicle: {},
};

const subscriptionSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    fetch: (state) => {
      state.loading = true;
      state.message = null;
      state.error = null;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    setMessage: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
    },
    clear: (state) => {
      state.loading = false;
      state.error = null;
      state.message = null;
    },
    setSubs: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.subscriptions = payload;
    },
    setUserSubs: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.userSubscriptions = payload;
    },
    setSub: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.subscription = payload;
    },
    setView: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.currentView = payload;
    },
    setVehicle: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.vehicle = payload;
    },
  },
});

export const {
  setError,
  fetch,
  setMessage,
  clear,
  setSub,
  setSubs,
  setUserSubs,
  setView,
  setVehicle,
} = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
export const subscriptionsSelector = (state) => state.subscriptions;

// Fetch Subs
export function fetchSubscriptions(data) {
  return async (dispatch) => {
    dispatch(fetch());

    try {
      const res = await api.post("/subscription_plans", data);
      dispatch(setSubs(res.data.data));
    } catch (error) {
      if (error?.response?.status === 500) {
        dispatch(setError("Something went wrong, please try again"));
      } else {
        dispatch(setError(error?.response?.data?.message));
      }
      dispatch(clear());
      if (error?.response?.status === 401) {
        dispatch(logoutUser());
      }
    }
  };
}

// Fetch User Subs
export function fetchUserSubscriptions() {
  return async (dispatch) => {
    dispatch(fetch());

    try {
      const res = await api.get("/user_subscription");
      dispatch(setUserSubs(res.data.data));
    } catch (error) {
      if (error?.response?.status === 500) {
        dispatch(setError("Something went wrong, please try again"));
      } else {
        dispatch(setError(error?.response?.data?.message));
      }
      dispatch(clear());
      if (error?.response?.status === 401) {
        dispatch(logoutUser());
      }
    }
  };
}

// Fetch Sub
export function fetchSubscription(id) {
  return async (dispatch) => {
    dispatch(fetch());

    try {
      const res = await api.get(`/user-subscription/${id}`);
      dispatch(setSub(res.data.data));
    } catch (error) {
      if (error?.response?.status === 500) {
        dispatch(setError("Something went wrong, please try again"));
      } else {
        dispatch(setError(error?.response?.data?.message));
      }
      dispatch(clear());
      if (error?.response?.status === 401) {
        dispatch(logoutUser());
      }
    }
  };
}

// Create Sub
export function createSubscription(data) {
  return async (dispatch) => {
    dispatch(fetch());

    try {
      const res = await api.post(`/user_subscription`, data);
      dispatch(setSub(res.data.data));
      dispatch(setMessage("Subscription successful"));
    } catch (error) {
      if (error?.response?.status === 500) {
        dispatch(setError("Something went wrong, please try again"));
      } else {
        const errors = error?.response?.data?.errors;
        Object.keys(errors).map((error) =>
          dispatch(setError(errors[error][0]))
        );
      }
      dispatch(clear());
      if (error?.response?.status === 401) {
        dispatch(logoutUser());
      }
    }
  };
}
