import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Loader from "react-loader-spinner";
import { toast } from "react-hot-toast";
import { View } from "../styles/login.styles";
import { ReactComponent as LogoIcon } from "../assets/images/logoWhite.svg";
import { ReactComponent as ShowIcon } from "../assets/icons/show-icon.svg";
import { ReactComponent as HideIcon } from "../assets/icons/hide-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  loginUser,
  resetPassword,
  userSelector,
} from "../redux/features/userSlice";
import InputModal from "../components/shared/InputModal";
import { ReactComponent as CloseIcon } from "../assets/icons/close-icon.svg";

const schema = yup
  .object({
    email: yup.string().email("Must be a valid email").required(),
    password: yup.string().required(),
  })
  .required();

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading, error, isAuthenticated, message } =
    useSelector(userSelector);
  const [showPassword, setShowPassword] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data, e) => {
    e.preventDefault();
    dispatch(loginUser(data));
  };

  useEffect(() => {
    error && toast.error(error);
  }, [error]);

  useEffect(() => {
    message && toast.success(message);
  }, [message]);

  // redirect user to home page after successful Login
  useEffect(() => {
    if (isAuthenticated) {
      history.push("/dashboard");
    }
  }, [isAuthenticated, history]);

  const handleResetPassword = () => {
    setShowModal(false);
    const data = { email };
    dispatch(resetPassword(data));
  };

  return (
    <View>
      <div className='content'>
        <div className='left-side'>
          <LogoIcon />
          <div className='info'>
            <h2>Welcome back. We’ve missed you</h2>
            <p>Let’s get that car fixed today.</p>
          </div>
        </div>
        <div className='right-side'>
          {/* {showLink !== null ? (
            <div className='reset-link'>
              <a href={showLink} onClick={() => dispatch(setShowLink(null))}>
                Reset Password
              </a>
            </div>
          ) : null} */}
          <div className='form'>
            <h3>Log In</h3>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* ==== Email ===== */}
              <div className='input-view'>
                <label htmlFor='email'>Email Address</label>
                <input
                  type='text'
                  placeholder='Enter email address'
                  {...register("email")}
                />

                <p>{errors.email?.message}</p>
              </div>

              {/* ===== Password ====== */}
              <div className='input-view'>
                <label htmlFor='email'>Password</label>
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder='Enter password'
                  {...register("password")}
                />
                {showPassword ? (
                  <button
                    type='button'
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <HideIcon />
                  </button>
                ) : (
                  <button
                    type='button'
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <ShowIcon />
                  </button>
                )}
                <p>{errors.password?.message}</p>
              </div>

              <div className='forgot'>
                <button type='button' onClick={() => setShowModal(true)}>
                  Forgot Password?
                </button>
              </div>

              <button type='submit' className='btn'>
                {loading ? (
                  <Loader
                    type='Oval'
                    color='#FFF'
                    height={35}
                    width={35}
                    timeout={10000}
                  />
                ) : (
                  "Log In"
                )}
              </button>
            </form>
            {/* ========== Register =========== */}
            <div className='register'>
              <span>Don’t have an account? </span>{" "}
              <Link to='/signup'>Signup</Link>
            </div>
          </div>
        </div>
      </div>

      <InputModal showModal={showModal}>
        <header>
          <h3>Reset Password</h3>

          <button onClick={() => setShowModal(false)}>
            <CloseIcon />
          </button>
        </header>
        <p>
          Enter the email address associated with your account and we'll send
          you a link to reset your password.
        </p>

        <form>
          {/* ===== License Number ==== */}
          <div className='input-view'>
            <label htmlFor='email'>Email</label>
            <input
              type='text'
              placeholder='Enter email address'
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />

            {/* <p>{errors.license_no?.message}</p> */}
          </div>

          <div className='cta'>
            <button type='button' onClick={handleResetPassword}>
              Continue
            </button>
          </div>
        </form>
      </InputModal>
    </View>
  );
};

export default Login;
