import Dashboard from "../pages/Dashboard";
import { ReactComponent as HomeIcon } from "../assets/icons/home-icon.svg";
import { ReactComponent as BookingsIcon } from "../assets/icons/booking-icon.svg";
import { ReactComponent as VehicleIcon } from "../assets/icons/vehicle-icon.svg";
import { ReactComponent as SubIcon } from "../assets/icons/sub-icon.svg";
import { ReactComponent as JobIcon } from "../assets/icons/job-icon.svg";
import { ReactComponent as WalletIcon } from "../assets/icons/wallet-icon.svg";
import Bookings from "../pages/Bookings";
import Vehicles from "../pages/Vehicles";
import JobHistory from "../pages/JobHistory";
import Wallet from "../pages/Wallet";
import Settings from "../pages/Settings";
import Job from "../pages/Job";
import BookDiagnosis from "../pages/BookDiagnosis";
import QuoteDetails from "../pages/QuoteDetails";
import Vehicle from "../pages/Vehicle";
import SubscriptionDetails from "../pages/SubscriptionDetails";
import Subscriptions from "../pages/Subscriptions";
// import NotFound from "../pages/404";

export const routes = [
  {
    component: Dashboard,
    path: "/dashboard",
    icon: HomeIcon,
    name: "Dashboard",
    show: true,
  },
  {
    component: Bookings,
    path: "/bookings",
    icon: BookingsIcon,
    name: "Bookings",
    show: true,
  },
  {
    component: Vehicles,
    path: "/vehicles",
    icon: VehicleIcon,
    name: "Vehicles",
    show: true,
  },
  {
    component: JobHistory,
    path: "/service-history",
    icon: JobIcon,
    name: "Service History",
    show: true,
  },
  {
    component: Subscriptions,
    path: "/subscriptions",
    icon: SubIcon,
    name: "Subscriptions",
    show: true,
  },
  {
    component: Wallet,
    path: "/wallet",
    icon: WalletIcon,
    name: "Wallet",
    show: false,
  },
  {
    component: Settings,
    path: "/settings",
    icon: WalletIcon,
    name: "Settings",
    show: false,
  },
  {
    component: Job,
    path: "/service-history/:id",
    icon: WalletIcon,
    name: "Job",
    show: false,
  },
  {
    component: SubscriptionDetails,
    path: "/subscription-details",
    icon: WalletIcon,
    name: "Subscription Details",
    show: false,
  },
  {
    component: Vehicle,
    path: "/vehicles/:id",
    icon: WalletIcon,
    name: "Vehicle",
    show: false,
  },
  {
    component: BookDiagnosis,
    path: "/book-diagnosis",
    icon: WalletIcon,
    name: "Book",
    show: false,
  },
  {
    component: QuoteDetails,
    path: "/quote-details",
    icon: WalletIcon,
    name: "QuoteDetails",
    show: false,
  },
  // {
  //   component: NotFound,
  //   path: "*",
  //   icon: WalletIcon,
  //   name: "QuoteDetails",
  //   show: false,
  // },
];
