import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";

export const GlobalStyle = createGlobalStyle`
${normalize}
  *, *::before, *::after {
  box-sizing: border-box;
}
* {
  margin: 0;
}
html, body {
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.white};
  color: ${({ theme }) => theme.grey_700};
  font-family: 'Exo', sans-serif;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

}
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}
input, button, textarea, select {
  font: inherit;
}
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
#root, #__next {
  isolation: isolate;
}

.open {
  color: ${({ theme }) => theme.blue_600} !important;
  background-color: ${({ theme }) => theme.blue_50} !important;
  height: 10px !important;
  padding: 0.2rem !important;
  border-radius: 20px ;
}
.closed {
  color: ${({ theme }) => theme.white} !important;
  background-color: ${({ theme }) => theme.green} !important;
  padding: 0.2rem;
  border-radius: 20px;
}
.awaiting {
  color: ${({ theme }) => theme.amber} !important;
}
.progress {
  color: ${({ theme }) => theme.white} !important;
  background-color: ${({ theme }) => theme.yellow} !important;
  padding: 0.2rem;
  border-radius: 20px;

}
.more_details{
  display: flex;
  width: 100%;
  justify-content: space-between;

  .quote_details{
    width: 80%;
  }
}
.image_span{
  max-width: 100px;
  max-height: 80%;
}
.not_done {
  color: ${({ theme }) => theme.red} !important;
}
.delivered {
  color: ${({ theme }) => theme.black} !important;
  background-color: ${({ theme }) => theme.green_50} !important;
  padding: 0.2rem;
  border-radius: 20px;
}
`;
