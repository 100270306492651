import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Loader from "react-loader-spinner";
import { toast } from "react-hot-toast";
import { View } from "../styles/login.styles";
import { ReactComponent as LogoIcon } from "../assets/images/logoWhite.svg";
import { ReactComponent as ShowIcon } from "../assets/icons/show-icon.svg";
import { ReactComponent as HideIcon } from "../assets/icons/hide-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  confirmPasswordReset,
  userSelector,
} from "../redux/features/userSlice";

const schema = yup
  .object({
    password: yup.string().required(),
  })
  .required();

const ResetPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const search = location.search;
  const token = new URLSearchParams(search).get("token");
  const { loading, error, message } = useSelector(userSelector);
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data, e) => {
    e.preventDefault();
    const body = { ...data, token };
    dispatch(confirmPasswordReset(body));
  };

  useEffect(() => {
    error && toast.error(error);
  }, [error]);

  useEffect(() => {
    message && toast.success(message);
  }, [message]);

  // redirect user to home page after successful Login
  useEffect(() => {
    if (message === "Password reset successful, Proceed to login") {
      history.push("/login");
    } else return;
  }, [message, history]);

  return (
    <View>
      <div className='content'>
        <div className='left-side'>
          <LogoIcon />
          <h2>Create new password</h2>
          <p>Let’s get back right in.</p>
        </div>
        <div className='right-side'>
          <div className='form'>
            <h3>Reset Password</h3>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* ===== Password ====== */}
              <div className='input-view'>
                <label htmlFor='email'>Password</label>
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder='Enter password'
                  {...register("password")}
                />
                {showPassword ? (
                  <button
                    type='button'
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <HideIcon />
                  </button>
                ) : (
                  <button
                    type='button'
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <ShowIcon />
                  </button>
                )}
                <p>{errors.password?.message}</p>
              </div>

              <button type='submit' className='btn'>
                {loading ? (
                  <Loader
                    type='Oval'
                    color='#FFF'
                    height={35}
                    width={35}
                    timeout={10000}
                  />
                ) : (
                  "Reset Password"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </View>
  );
};

export default ResetPassword;
