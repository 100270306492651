import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as NotFoundIcon } from "../assets/icons/404-icon.svg";

const NotFound = () => {
  return (
    <View>
      <div className='content'>
        <NotFoundIcon />
        <p>Sorry, page not found</p>
        <Link to='/'>Go back home</Link>
      </div>
    </View>
  );
};

export default NotFound;

const View = styled.div`
  width: 100vw;
  height: 100vh;

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      margin-top: 20px;
    }

    a {
      margin-top: 10px;
      background-color: ${({ theme }) => theme.black};
      color: ${({ theme }) => theme.white};
      padding: 6px 20px;
      text-decoration: none;
      border-radius: 4px;
    }
  }
`;
