const size = {
  phones: "600px",
  tablets: "900px",
  landscape_tablets: "768px",
  laptops: "910px",
  desktops: "1200px",
};

export const device = {
  phone: `(max-width: ${size.phones})`, // Extra small devices (phones, 600px and down)
  tablet: `(max-width: ${size.tablets})`, // Small devices (portrait tablets and large phones, 600px and up)
  landscape_tablets: `(min-width: ${size.landscape_tablets})`, // Medium devices (landscape tablets, 768px and up)
  laptops: `(min-width: ${size.laptops})`, // Large devices (laptops/desktops, 992px and up)
  destops: `(min-width: ${size.desktops})`, // Extra large devices (large laptops and desktops, 1200px and up)
};
