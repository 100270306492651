import React, { useState } from "react";
import styled from "styled-components";
import { format } from "date-fns";
import { device } from "../../constants/breakpoints";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const Overview = ({ job, setActiveView }) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <View>
      <div className='col1'>
        <h4>Vehicle Details</h4>

        <div className='item'>
          <span>Make</span>
          <p>{job?.vehicle?.make}</p>
        </div>
        <div className='item'>
          <span>Model</span>
          <p>{job?.vehicle?.model}</p>
        </div>
        <div className='item'>
          <span>Year</span>
          <p>{job?.vehicle?.year}</p>
        </div>
        <div className='item'>
          <span>VIN</span>
          <p>{job?.vehicle_vin}</p>
        </div>
        <div className='item'>
          <span>License Number</span>
          <p>{job?.vehicle?.license_no}</p>
        </div>
        <div className='item'>
          <span>Mileage In</span>
          <p>{job?.mileage_in}</p>
        </div>
        <div className='item'>
          <span>Fuel Level</span>
          <p>{job?.fuel_level_in}</p>
        </div>
        <div className='item'>
          <span>Service Advisor</span>
          <p>{job?.service_advisor?.name}</p>
        </div>
      </div>
      <div className='col2'>
        <div className='row1'>
          <div className='box'>
            <h4>My Complaints</h4>
            <p>{job?.customer_complaint}</p>
          </div>
          <div className='box'>
            <h4>Status History</h4>

            {job?.status_history?.map((item) => {
              return (
                <div className='item'>
                  <p>{item?.status}</p>
                  <span>
                    {format(new Date(item?.timestamp), "do MMM yyyy, hh:mm a")}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
        <div className='row2'>
          <p>Photos</p>
          <div className='images'>
            {job?.uploads?.map((image) => {
              return (
                <img
                  src={image?.full_url}
                  alt='vehicle uploads'
                  onClick={() => setIsOpen(true)}
                />
              );
            })}

            {isOpen && (
              <Lightbox
                mainSrc={job?.uploads[photoIndex]?.full_url}
                nextSrc={
                  job?.uploads[(photoIndex + 1) % job?.uploads.length]?.full_url
                }
                prevSrc={
                  job?.uploads[
                    (photoIndex + job?.uploads.length - 1) % job?.uploads.length
                  ]?.full_url
                }
                onCloseRequest={() => setIsOpen(false)}
                onMovePrevRequest={() =>
                  setPhotoIndex(
                    (photoIndex + job?.uploads.length - 1) % job?.uploads.length
                  )
                }
                onMoveNextRequest={() =>
                  setPhotoIndex((photoIndex + 1) % job?.uploads.length)
                }
              />
            )}
          </div>

          <div className='cta'>
            <button onClick={() => setActiveView(1)}>View Quotes</button>
          </div>
        </div>
      </div>
    </View>
  );
};

export default Overview;

const View = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 20px 0;

  @media ${device.phone} {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .col1 {
    width: 40%;
    background-color: ${({ theme }) => theme.white};
    border-radius: 4px;
    padding: 20px;

    @media ${device.phone} {
      width: 100%;
      padding: 12px;
      margin-bottom: 20px;
    }

    h4 {
      font-weight: 400;
      margin-bottom: 20px;
    }

    .item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px;
      border-bottom: 1px solid ${({ theme }) => theme.grey_200};

      span {
        color: ${({ theme }) => theme.grey_400};
        font-weight: 500;
      }
      p {
        color: ${({ theme }) => theme.grey_700};
        font-weight: 500;
      }
    }
  }

  .col2 {
    width: 57%;
    display: flex;
    flex-direction: column;

    @media ${device.phone} {
      width: 100%;
    }

    .row1 {
      width: 100%;
      display: flex;
      justify-content: space-between;

      @media ${device.phone} {
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
      }

      .box {
        width: 48%;
        min-height: 200px;
        max-height: 300px;
        overflow-y: scroll;
        border-radius: 4px;
        background-color: ${({ theme }) => theme.white};
        padding: 12px;

        h4 {
          font-weight: 400;
          margin-bottom: 20px;
        }

        p {
          text-transform: capitalize;
        }

        .item {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            text-transform: capitalize;
            font-weight: 500;
          }

          span {
            font-weight: 400;
            color: ${({ theme }) => theme.grey_400};
            font-size: 0.9rem;
          }
        }

        @media ${device.phone} {
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }

    .row2 {
      width: 100%;
      padding: 20px;
      border-radius: 4px;
      background-color: ${({ theme }) => theme.white};
      margin-top: 20px;

      .images {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 20px;

        img {
          width: 32%;
          height: 120px;
          object-fit: contain;

          @media ${device.phone} {
            width: 100%;
            margin-bottom: 10px;
            height: 200px;
          }
        }
      }

      .cta {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        margin-top: 30px;

        button {
          background-color: transparent;
          text-decoration: underline;
          border: none;
          outline: none;
          color: ${({ theme }) => theme.blue_600};
          cursor: pointer;
        }
      }
    }
  }
`;
