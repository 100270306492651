import React from "react";
import styled from "styled-components";
import Sidebar from "./shared/Sidebar";

const Layout = ({ children }) => {
  return (
    <LayoutView>
      <Sidebar />
      <div className='children'>{children}</div>
    </LayoutView>
  );
};

export default Layout;

const LayoutView = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: ${(props) => props.theme.black};

  .children {
    width: 80%;
    height: 100%;
    background-color: ${(props) => props.theme.white};
  }
`;
