import React, { useEffect, useState, useRef } from "react";
import Layout from "../components/Layout";
import PageHeader from "../components/shared/PageHeader";
import { View } from "../styles/jobs.styles";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { useDebounce } from "rooks";
import {
  fetchJobs,
  fetchJobWithVin,
  filterRequests,
  jobsSelector,
  searchJobs,
} from "../redux/features/jobsSlice";
import { ReactComponent as PrevIcon } from "../assets/icons/prev-icon.svg";
import { ReactComponent as NextIcon } from "../assets/icons/next-icon.svg";
import { ReactComponent as FilterIcon } from "../assets/icons/filter-icon.svg";
import { ReactComponent as UploadIcon } from "../assets/icons/upload-icon.svg";
import ModalLoader from "../components/shared/Loader";
import useOnClickOutside from "../hooks/useClickOutside";
import DataTable from "../components/MaterialTable";

const JobHistory = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const query = location.search;
  const ref = useRef();
  const { jobs, jobsMeta, loading } = useSelector(jobsSelector);
  const [page, setPage] = useState(jobsMeta?.current_page || 1);
  const [rows, setRows] = useState(10);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [service_type, setServiceType] = useState("");
  const [openFilterMenu, setOpenFilterMenu] = useState(false);
  const vin = new URLSearchParams(query).get("vin");

  useOnClickOutside(ref, () => setOpenFilterMenu(false));
  const setValueDebounced = useDebounce(setSearch, 1000);

  useEffect(() => {
    if (!vin) {
      dispatch(fetchJobs(page, rows));
    } else {
      dispatch(fetchJobWithVin(vin, page, rows));
    }
  }, [page, rows, vin, dispatch]);

  const fetchNextPage = () => {
    if (jobsMeta?.last_page > jobsMeta?.current_page) {
      setPage(jobsMeta?.current_page + 1);
    }
  };

  const fetchPrevPage = () => {
    if (jobsMeta?.current_page > 1) {
      setPage(jobsMeta?.current_page - 1);
    }
  };

  const handleSearch = () => {
    dispatch(filterRequests(status, service_type));
  };

  useEffect(() => {
    search && dispatch(searchJobs(search));

    // eslint-disable-next-line
  }, [search]);

  return (
    <Layout>
      <View>
        <PageHeader title="Service History" />
        <div className="content">
          <div className="table-view">
            <div className="header">
              <div className="search-view">
                {openFilterMenu && (
                  <div className="filter-box" ref={ref}>
                    <div className="head">
                      <FilterIcon /> <span>Filter</span>
                    </div>
                    <div className="input">
                      <select
                        name="status"
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <option value="" defaultChecked>
                          Status
                        </option>
                        <option value="open">Open</option>
                        <option value="approved">Approved</option>
                      </select>
                    </div>
                    <div className="input">
                      <select
                        name="service_type"
                        onChange={(e) => setServiceType(e.target.value)}
                      >
                        <option value="" defaultChecked>
                          Service Type
                        </option>
                        <option value="pms">PMS</option>
                      </select>
                    </div>
                    <div className="cta">
                      <button
                        className="clear"
                        onClick={() => dispatch(fetchJobs(page, rows))}
                      >
                        Clear
                      </button>
                      <button onClick={handleSearch}>Filter</button>
                    </div>
                  </div>
                )}
                <input
                  type="text"
                  onChange={(e) => setValueDebounced(e.target.value)}
                  placeholder="Search by job no, vin, vehicle make, model and license_no"
                />
                <button
                  data-tip
                  data-for="filterTip"
                  onClick={() => setOpenFilterMenu(true)}
                >
                  <FilterIcon />
                </button>
                <ReactTooltip id="filterTip" place="bottom" effect="solid">
                  Filter
                </ReactTooltip>
              </div>
              <button className="export">
                <UploadIcon /> <span>Export</span>
              </button>
            </div>

            <DataTable rows={jobs} />

            {jobs?.length < 1 ? (
              <div className="empty">
                <p>No result found</p>
              </div>
            ) : null}

            {jobsMeta !== {} ? (
              <div className="bottom">
                <div className="pagination">
                  <button onClick={fetchPrevPage}>
                    <PrevIcon />
                  </button>
                  <span>
                    {jobsMeta?.current_page} of {jobsMeta?.last_page}
                  </span>
                  <button onClick={fetchNextPage}>
                    <NextIcon />
                  </button>
                </div>

                <div className="row">
                  <span>Show:</span>
                  <select name="rows" onChange={(e) => setRows(e.target.value)}>
                    <option value="10" defaultChecked>
                      10 rows
                    </option>
                    <option value="15" defaultChecked>
                      15 rows
                    </option>
                    <option value="20" defaultChecked>
                      20 rows
                    </option>
                  </select>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </View>
      <ModalLoader showModal={loading} />;
    </Layout>
  );
};

export default JobHistory;
