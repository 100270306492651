import styled from "styled-components";

import { ReactComponent as SettingsIcon } from "../../assets/icons/settings-icon.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout-icon.svg";
import { ReactComponent as Logo } from "../../assets/images/logoDark.svg";
import { routes } from "../../constants/routes";
import { useLocation, Link } from "react-router-dom";
import { device } from "../../constants/breakpoints";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../redux/features/userSlice";

export default function Sidebar() {
  const location = useLocation();
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(logoutUser());
  };
  return (
    <View>
      <div className='content'>
        {/* ======= Store Name ===== */}
        <div className='logo'>
          <Logo />
        </div>

        {/* ====== Nav Links ====== */}
        <div className='nav-links'>
          {routes.map((route) => {
            const Icon = route.icon;
            return route?.show ? (
              <Link
                to={route.path}
                key={route.name}
                className={location.pathname === route.path ? "active" : ""}
              >
                <Icon
                  className={
                    location.pathname === route.path ? "active-icon" : "icon"
                  }
                />
                {route.name}
              </Link>
            ) : null;
          })}
        </div>

        <div className='footer'>
          <div className='nav-links'>
            <Link
              to='/settings'
              className={location.pathname === "settings" ? "active" : ""}
            >
              <SettingsIcon
                className={
                  location.pathname === "settings" ? "active-icon" : "icon"
                }
              />
              Settings
            </Link>
            <button className='logout' onClick={logout}>
              {" "}
              <LogoutIcon />
              <span>Logout</span>
            </button>
          </div>
        </div>
      </div>
    </View>
  );
}

const View = styled.nav`
  height: 100vh;
  display: flex;
  flex-direction: column;
  width: 20%;
  background-color: ${({ theme }) => theme.grey_900};
  padding: 30px 20px;
  padding-top: 20px;
  position: relative;
  z-index: 9000;
  border-right: 1px solid ${({ theme }) => theme.grey_200};

  @media ${device.tablet} {
    display: none;
  }

  @media ${device.phone} {
    display: none;
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;

    .logo {
      width: 100%;
      margin-bottom: 30px;

      .avatar {
        width: 36px;
        height: 36px;
        border-radius: 50%;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 50%;
        }
      }

      .store-name {
        width: 65%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        span {
          font-size: 0.8rem;
          font-weight: 400;
        }

        img {
          width: 12px;
          height: 10px;
          object-fit: contain;
          margin-left: 8px;
        }
      }
    }

    .nav-links {
      width: 100%;
      display: flex;
      flex-direction: column;

      a {
        width: 100%;
        height: 45px;
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 0.9rem;
        color: ${({ theme }) => theme.grey_400};
        margin-bottom: 10px;
        padding: 10px;
        transition: all 0.2s ease;
        text-decoration: none;

        &:hover {
          background-color: ${({ theme }) => theme.grey_700};
          border-radius: 4px;
          color: ${({ theme }) => theme.white};
        }

        &:hover .icon path {
          stroke: ${({ theme }) => theme.white};
        }

        img {
          margin-right: 16px;
        }

        .icon {
          margin-right: 16px;

          &:hover path {
            stroke: ${({ theme }) => theme.white};
          }
        }

        .active-icon {
          margin-right: 16px;

          &:hover {
            stroke: ${({ theme }) => theme.white};
          }
        }

        .active-icon path {
          stroke: ${({ theme }) => theme.white};

          &:hover {
            stroke: ${({ theme }) => theme.white};
          }
        }

        .active-icon ellipse {
          stroke: ${({ theme }) => theme.white};
          fill: ${({ theme }) => theme.white};
        }
      }

      .active {
        color: ${({ theme }) => theme.white};
        background-color: ${({ theme }) => theme.grey_700};
        border-radius: 4px;

        .icon {
          margin-right: 16px;
        }

        img {
          margin-right: 16px;
        }
      }
    }

    .footer {
      position: absolute;
      bottom: 30px;
      display: flex;
      flex-direction: column;
      width: 100%;
      left: 0;
      padding: 30px 20px;
      padding-bottom: 0;

      @media ${device.tablet} {
      }

      .nav-links {
        width: 100%;
        display: flex;
        flex-direction: column;

        a {
          width: 100%;
          height: 45px;
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 0.9rem;
          color: ${({ theme }) => theme.grey_400};
          margin-bottom: 16px;
          padding: 10px;
          transition: all 0.2s ease;

          &:hover {
            background-color: ${({ theme }) => theme.grey_700};
            border-radius: 4px;
            color: ${({ theme }) => theme.white};
          }

          img {
            margin-right: 16px;
          }

          .icon {
            margin-right: 16px;
          }

          .active-icon {
            margin-right: 16px;
          }

          .active-icon path {
            /* fill: ${({ theme }) => theme.blue_600}; */
            stroke: ${({ theme }) => theme.blue_600};
          }

          .active-icon ellipse {
            stroke: ${({ theme }) => theme.blue_600};
            fill: ${({ theme }) => theme.blue_600};
          }
        }

        .active {
          color: ${({ theme }) => theme.blue_600};
          background-color: ${({ theme }) => theme.blue_600};
          border-radius: 4px;
        }

        .logout {
          width: 100%;
          height: 40px;
          display: flex;
          align-items: center;

          color: ${({ theme }) => theme.grey_400};
          margin-bottom: 16px;
          padding: 10px;
          transition: all 0.2s ease;
          background-color: transparent;
          cursor: pointer;
          border: none;
          outline: none;

          &:hover {
            background-color: ${({ theme }) => theme.grey_700};
            border-radius: 4px;
            color: ${({ theme }) => theme.white};
          }

          span {
            margin-left: 16px;
            font-size: 0.9rem;
            font-weight: 400;
          }
        }
      }

      span {
        text-align: left;
        color: ${({ theme }) => theme.grey_400};
        font-size: 0.8rem;

        &:hover {
          background-color: ${({ theme }) => theme.grey_700};
          color: ${({ theme }) => theme.white};
        }
      }
    }
  }
`;
