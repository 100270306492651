import axios from "axios";

// Create an API Instance
const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "x-api-key": process.env.REACT_APP_X_API_KEY,
  },
});

// Intercept requests
api.interceptors.request.use(
  async (config) => {
    const token = await localStorage.getItem("@UT");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

//Intercept response
api.interceptors.response.use(
  (response) =>
    new Promise((resolve, reject) => {
      resolve(response);
    }),
  (error) => {
    if (!error.response) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    if (error.response && error.response.status === 401) {
      // window.location = "/";
      localStorage.clear();

      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
);

export default api;
