import { createSlice } from "@reduxjs/toolkit";
import api from "../../helpers/api";
import { logoutUser } from "./userSlice";

const initialState = {
  loading: false,
  error: null,
  message: null,
  jobs: [],
  job: {},
  jobsMeta: {},
  quotes: [],
  quote: {},
  quotesMeta: {},
};

const jobsSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    fetch: (state) => {
      state.loading = true;
      state.message = null;
      state.error = null;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    setMessage: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
    },
    clear: (state) => {
      state.loading = false;
      state.error = null;
      state.message = null;
    },
    setJobs: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.jobs = payload;
    },
    setJobsMeta: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.jobsMeta = payload;
    },
    setQuotes: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.quotes = payload;
    },
    setQuote: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.quote = payload;
    },
    setQuotesMeta: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.quotesMeta = payload;
    },
    setJob: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.job = payload;
    },
    alterQuote: (state, { payload }) => {
      state.loading = false;
      const q = state.quote;

      q.line_items_breakdown = payload.line_items_breakdown;

      state.quote = q;
    },
  },
});

export const {
  setError,
  fetch,
  setMessage,
  clear,
  setJob,
  setJobs,
  setJobsMeta,
  setQuotes,
  setQuotesMeta,
  alterQuote,
  setQuote,
} = jobsSlice.actions;
export default jobsSlice.reducer;
export const jobsSelector = (state) => state.jobs;

// Fetch Jobs
export function fetchJobs(page, rows) {
  return async (dispatch) => {
    dispatch(fetch());

    try {
      const res = await api.get(`/jobcards?per_page=${rows}&page=${page}`);
      dispatch(setJobs(res.data.data));
      dispatch(setJobsMeta(res.data.meta));
    } catch (error) {
      if (error?.response?.status === 500) {
        dispatch(setError("Something went wrong, please try again"));
      } else {
        dispatch(setError(error?.response?.data?.message));
      }
      dispatch(clear());
      if (error?.response?.status === 401) {
        dispatch(logoutUser());
      }
    }
  };
}

// Fetch Job
export function fetchJob(id) {
  return async (dispatch) => {
    dispatch(fetch());

    try {
      const res = await api.get(`/jobcards/${id}`);
      dispatch(setJob(res.data.data));
    } catch (error) {
      if (error?.response?.status === 500) {
        dispatch(setError("Something went wrong, please try again"));
      } else {
        dispatch(setError(error?.response?.data?.message));
      }
      dispatch(clear());
      if (error?.response?.status === 401) {
        dispatch(logoutUser());
      }
    }
  };
}

// Fetch Job
export function fetchJobWithVin(vin) {
  return async (dispatch) => {
    dispatch(fetch());

    try {
      const res = await api.get(`/jobcards?search=${vin}`);
      dispatch(setJobs(res.data.data));
      dispatch(setJobsMeta(res.data.meta));
    } catch (error) {
      if (error?.response?.status === 500) {
        dispatch(setError("Something went wrong, please try again"));
      } else {
        dispatch(setError(error?.response?.data?.message));
      }
      dispatch(clear());
      if (error?.response?.status === 401) {
        dispatch(logoutUser());
      }
    }
  };
}

//  Filter Jobs
export function filterRequests(status, service_type) {
  return async (dispatch) => {
    dispatch(fetch());

    try {
      const res = await api.get(
        `/jobcards?service_type=${service_type}&status=${status}`
      );
      dispatch(setJobs(res.data.data));
      // dispatch(setJobsMeta({}));
    } catch (error) {
      if (error?.response?.status === 500) {
        dispatch(setError("Something went wrong, please try again"));
      } else {
        dispatch(setError(error?.response?.data?.message));
      }
    }
  };
}

//  Search Jobs
export function searchJobs(search) {
  return async (dispatch) => {
    dispatch(fetch());

    try {
      const res = await api.get(`/jobcards?search=${search}`);
      dispatch(setJobs(res.data.data));
      // dispatch(setJobsMeta({}));
    } catch (error) {
      if (error?.response?.status === 500) {
        dispatch(setError("Something went wrong, please try again"));
      } else {
        dispatch(setError(error?.response?.data?.message));
      }
    }
  };
}

//  Get the quotes related to a jobcard
export function fetchQuotes(ref, rows, page) {
  return async (dispatch) => {
    dispatch(fetch());

    try {
      const res = await api.get(
        `/jobcards/${ref}/quotes?per_page=${rows}&page=${page}`
      );

      dispatch(setQuotes(res.data.data));
      dispatch(setQuotesMeta(res.data.meta));
    } catch (error) {
      if (error?.response?.status === 500) {
        dispatch(setError("Something went wrong, please try again"));
      } else {
        dispatch(setError(error?.response?.data?.message));
      }
    }
  };
}

// Calculate/Recalculate the total cost of a quote
export function calculateQuote(id, data) {
  return async (dispatch) => {
    dispatch(fetch());

    try {
      const res = await api.post(`/quotes/${id}/calculate`, data);
      dispatch(alterQuote(data));
      dispatch(setQuote(res.data.data));
    } catch (error) {
      if (error?.response?.status === 500) {
        dispatch(setError("Something went wrong, please try again"));
      } else {
        dispatch(setError(error?.response?.data?.message));
      }
      dispatch(clear());
      if (error?.response?.status === 401) {
        dispatch(logoutUser());
      }
    }
  };
}

// Approve Quotes
export function approveQuote(id, data) {
  return async (dispatch) => {
    dispatch(fetch());

    try {
      const res = await api.post(`/quotes/${id}/approve`, data);
      dispatch(setQuote(res.data.data));
      dispatch(setMessage("Quote has now been accepted"));
    } catch (error) {
      if (error?.response?.status === 500) {
        dispatch(setError("Something went wrong, please try again"));
      } else {
        dispatch(setError(error?.response?.data?.message));
      }
      dispatch(clear());
      if (error?.response?.status === 401) {
        dispatch(logoutUser());
      }
    }
    dispatch(clear());
  };
}

// Approve Single quote
export function approveSingleQuote(id, data) {
  return async (dispatch) => {
    dispatch(fetch());

    try {
      const res = await api.post(`/quotes/${id}/calculate`, data);
      dispatch(alterQuote(data));
      dispatch(setQuote(res.data.data));
      const body = {
        line_items_breakdown: res.data.data?.line_items_breakdown,
      };
      dispatch(approveQuote(id, body));
    } catch (error) {
      if (error?.response?.status === 500) {
        dispatch(setError("Something went wrong, please try again"));
      } else {
        dispatch(setError(error?.response?.data?.message));
      }
      dispatch(clear());
      if (error?.response?.status === 401) {
        dispatch(logoutUser());
      }
    }
  };
}
