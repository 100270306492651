import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { toast } from "react-hot-toast";
import Layout from "../components/Layout";
import PageHeader from "../components/shared/PageHeader";
import { fetchJobs, jobsSelector } from "../redux/features/jobsSlice";
import {
  fetchVehicleBrands,
  fetchVehicles,
  vehicleSelector,
} from "../redux/features/vehicleSlice";
import { View } from "../styles/dashboard.styles";
import { ReactComponent as SortIcon } from "../assets/icons/sort-icon.svg";
import { ReactComponent as EmptyIcon } from "../assets/icons/empty.svg";
import ModalLoader from "../components/shared/Loader";
import { bookingSelector, fetchBookings } from "../redux/features/bookingSlice";
import { userSelector } from "../redux/features/userSlice";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { dashboardVehicles, loading } = useSelector(vehicleSelector);
  const { message } = useSelector(userSelector);
  const { jobs } = useSelector(jobsSelector);
  const { bookings } = useSelector(bookingSelector);

  useEffect(() => {
    dispatch(fetchVehicles());
    dispatch(fetchJobs(1, 10));
    dispatch(fetchBookings(1, 10));
    dispatch(fetchVehicleBrands());
  }, [dispatch]);

  useEffect(() => {
    message && toast.success(message);
  }, [message]);

  return (
    <Layout>
      <View>
        <PageHeader title='Dashboard' />
        <div className='content'>
          {dashboardVehicles.length > 0 && <h2>My Vehicles</h2>}
          <div className='vehicles'>
            {dashboardVehicles.map((item) => {
              return (
                <div className='vehicle' key={item?.id}>
                  <span>{item?.year}</span>
                  <h3>
                    {item?.make}, {item?.model}
                  </h3>
                  <p>{item?.vin}</p>
                </div>
              );
            })}
          </div>

          <div className='table-row'>
            {/* === Recent Jobs === */}
            <div className='jobs'>
              <div className='header'>
                <h3>Recent Jobs</h3>
                <Link to='/service-history'>View all</Link>
              </div>

              <div className='table-head'>
                <div className='id'>
                  <span>Vehicle Id No</span>
                  <button>
                    <SortIcon />
                  </button>
                </div>
                <div className='status'>
                  <span>Status</span>
                  <button>
                    <SortIcon />
                  </button>
                </div>
                <div className='date'>
                  <span>Date</span>
                  <button>
                    <SortIcon />
                  </button>
                </div>
              </div>

              {jobs?.length > 0 ? (
                jobs?.slice(0, 6).map((item) => {
                  return (
                    <div className='table-item' key={item?.id}>
                      <div className='id'>
                        <span>{item?.vehicle_vin}</span>
                      </div>
                      <div
                        className={`status ${
                          item?.status === "Open"
                            ? "open"
                            : item?.status === "Approved"
                            ? "closed"
                            : ""
                        }`}
                      >
                        <span>{item?.status}</span>
                      </div>
                      <div className='date'>
                        <span>
                          {format(new Date(item?.created_at), "do MMM, yyyy")}
                        </span>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className='empty'>
                  <EmptyIcon />
                  <p>No result found</p>
                  <Link to='book-diagnosis'>Book an Appointment</Link>
                </div>
              )}
            </div>

            {/* === Vehicle Subscriptions */}
            <div className='jobs'>
              <div className='header'>
                <h3>Upcoming Appointments</h3>
                <Link to='/bookings'>View all</Link>
              </div>

              <div className='table-head'>
                <div className='id'>
                  <span>Vehicle Id No</span>
                  <button>
                    <SortIcon />
                  </button>
                </div>
                <div className='status'>
                  <span>Status</span>
                  <button>
                    <SortIcon />
                  </button>
                </div>
                <div className='date'>
                  <span>Date</span>
                  <button>
                    <SortIcon />
                  </button>
                </div>
              </div>

              {bookings?.length > 0 ? (
                bookings?.slice(0, 6)?.map((item) => {
                  return (
                    <div className='table-item' key={item?.id}>
                      <div className='id'>
                        <span>{item?.booking_number}</span>
                      </div>
                      <div
                        className={`status ${
                          item?.status === "pending"
                            ? "open"
                            : item?.status === "Closed"
                            ? "closed"
                            : ""
                        }`}
                      >
                        <span>{item?.status}</span>
                      </div>
                      <div className='date'>
                        <span>
                          {format(new Date(item?.created_at), "do MMM, yyyy")}
                        </span>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className='empty'>
                  <EmptyIcon />
                  <p>You have no pending appointment</p>
                  <Link to='book-diagnosis'>Book an Appointment</Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </View>
      <ModalLoader showModal={loading} />
    </Layout>
  );
};

export default Dashboard;
