import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../components/Layout";
import PageHeader from "../components/shared/PageHeader";
import { View } from "../styles/subs.styles";
import ModalLoader from "../components/shared/Loader";
import Overview from "../components/subsComponents/Overview";
import { setVehicle, vehicleSelector } from "../redux/features/vehicleSlice";
import {
  setView,
  subscriptionsSelector,
} from "../redux/features/subscriptionSlice";
import { fetchSubscriptions } from "../redux/features/subscriptionSlice";
import Plans from "../components/subsComponents/Plans";

const SubscriptionDetails = () => {
  const dispatch = useDispatch();
  const { vehicles, loading, vehicle } = useSelector(vehicleSelector);
  const { currentView } = useSelector(subscriptionsSelector);

  useEffect(() => {
    const data = {
      make: vehicle.make || vehicles[0].make,
      model: vehicle.model || vehicles[0].model,
      year: vehicle.year || vehicles[0].year,
      cylinders: vehicle.number_of_cylinders || vehicles[0].number_of_cylinders,
    };
    dispatch(fetchSubscriptions(data));
  }, [dispatch, vehicle, vehicles]);

  const unique = vehicles.filter((item) => item?.vin !== vehicle?.vin);

  return (
    <Layout>
      <View>
        <PageHeader title='Subscription' />

        {/* ==== Header View ==== */}
        <div className='header-view'>
          <div className='vehicle-view'>
            <select
              name='vehicle'
              onChange={(e) => dispatch(setVehicle(JSON.parse(e.target.value)))}
            >
              <option value={JSON.stringify(vehicles[0])} selected>
                {vehicle?.year} {vehicle?.make} {vehicle?.model}, {vehicle?.vin}
              </option>
              {unique?.map((item, i) => {
                return (
                  <option value={JSON.stringify(item)} key={i}>
                    {item?.year} {item?.make} {item?.model}, {item?.vin}
                  </option>
                );
              })}
            </select>
          </div>
          {/* =====TAB VIEW ==== */}
          <div className='tab-view'>
            <button
              onClick={() => dispatch(setView(0))}
              className={currentView === 0 ? "active" : ""}
            >
              Overview
            </button>
            <button
              onClick={() => dispatch(setView(1))}
              className={currentView === 1 ? "active" : ""}
            >
              Plans
            </button>
          </div>
        </div>

        <div className='content'>
          {currentView === 0 && <Overview />}
          {currentView === 1 && <Plans vehicle={vehicle} />}
        </div>
      </View>
      <ModalLoader showModal={loading} />
    </Layout>
  );
};

export default SubscriptionDetails;
