import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { useHistory } from "react-router";
import { toast } from "react-hot-toast";
import Layout from "../components/Layout";
import PageHeader from "../components/shared/PageHeader";
import {
  approveQuote,
  calculateQuote,
  jobsSelector,
} from "../redux/features/jobsSlice";
import { ReactComponent as SortIcon } from "../assets/icons/sort-icon.svg";
import { ReactComponent as RightIcon } from "../assets/icons/next-icon.svg";
import { ReactComponent as DropIcon } from "../assets/icons/drop-icon.svg";
import { ReactComponent as BackIcon } from "../assets/icons/back-icon.svg";
import { ReactComponent as Checkbox } from "../assets/icons/checkbox.svg";
import { ReactComponent as InfoIcon } from "../assets/icons/info-icon.svg";
import { ReactComponent as CheckFilled } from "../assets/icons/checkbox-filled.svg";
import { device } from "../constants/breakpoints";
import { numberWithCommas } from "../utils/numberWithCommas";
import { useDispatch } from "react-redux";
import ModalLoader from "../components/shared/Loader";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

const subMenuAnimate = {
  enter: {
    height: "auto",
    opacity: 1,
    transition: {
      duration: 0.2,
    },
    display: "block",
  },
  exit: {
    height: 0,
    opacity: 0,
    transition: {
      duration: 0.2,
      delay: 0.3,
    },
    transitionEnd: {
      display: "none",
    },
  },
};

const QuoteDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { quote, job, loading, message, error } = useSelector(jobsSelector);
  const [showMore, setShowMore] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const { line_items_breakdown } = quote;
  const image_video = line_items_breakdown.map(
    (item) => item.image_video || []
  );
  const { review } = image_video[0] || [];
  const imageUrls = image_video.map((url) => url?.base64?.replace(/"/g));

  const selectItems = (item, index) => {
    let d = { ...item };
    let q = [...quote.line_items_breakdown];

    d.approved = !d.approved;

    q[index] = d;

    dispatch(
      calculateQuote(quote.id, {
        line_items_breakdown: q,
        only_approved_items: true,
      })
    );
  };

  // const approveAQuote = (item, index) => {
  //   let d = { ...item };
  //   let q = [...quote.line_items_breakdown];

  //   d.approved = !d.approved;

  //   q[index] = d;

  //   dispatch(
  //     approveSingleQuote(quote.id, {
  //       line_items_breakdown: q,
  //       only_approved_items: true,
  //     })
  //   );
  // };

  // const selectAllItems = () => {};

  const approveQuotes = () => {
    const data = { line_items_breakdown: quote?.line_items_breakdown };
    dispatch(approveQuote(quote?.id, data));
  };

  useEffect(() => {
    error && toast.error(error);
  }, [error]);

  useEffect(() => {
    message && toast.success(message);
  }, [message]);

  return (
    <Layout>
      <View>
        <PageHeader title="Quote Details" />
        <button className="back" onClick={() => history.goBack()}>
          <BackIcon />
        </button>
        <div className="content">
          <div className="summary">
            <div className="head">
              <p>Summary</p>
            </div>
            <div className="list">
              <p>Labour Total</p>
              <span>₦{numberWithCommas(quote?.labour_markup) || 0.0}</span>
            </div>
            <div className="list">
              <p>Parts Total</p>
              <span>₦{numberWithCommas(quote?.parts_total_markup) || 0.0}</span>
            </div>
            <div className="list">
              <p>Subtotal</p>
              <span>₦{numberWithCommas(quote?.subtotal_markup) || 0.0}</span>
            </div>
            {quote?.taxes?.map((item) => (
              <div className="list">
                <p>
                  {item?.type} ({item?.percent}%)
                </p>
                <span>
                  ₦{numberWithCommas(Number(item?.value).toFixed(2)) || 0.0}
                </span>
              </div>
            ))}
            <div className="list">
              <p>Discount</p>
              <span>₦{numberWithCommas(quote?.discount_applied) || 0.0}</span>
            </div>
            <div className="total">
              <p>Total</p>
              <span>
                ₦
                {numberWithCommas(
                  Number(quote?.total_markup).toFixed(2) || 0.0
                )}
              </span>
            </div>
          </div>
          <div className="col2">
            <div className="info">
              <InfoIcon />
              <p>
                Tick the checkbox to approve each item you want this service to
                cover. Click on the APPROVE QUOTE button after all desired items
                have been selected
              </p>
            </div>
            <div className="header">
              <h3>Parts and Services</h3>
              <button onClick={approveQuotes}>Approve Quote</button>
            </div>
            <div className="table-view">
              <div className="table-head">
                <div className="arrow"></div>
                <div className="checkbox">
                  <button
                  // onClick={() => {
                  //   setSelectAll(!selectAll);
                  //   selectAllItems();
                  // }}
                  >
                    {/* {selectAll ? <CheckFilled /> : <Checkbox />} */}
                  </button>
                </div>
                <div className="part">
                  <span>Part/Service</span>
                  <button>
                    <SortIcon />
                  </button>
                </div>
                {review && (
                  <div className="image">
                    <span>Image</span>
                    <button>
                      <SortIcon />
                    </button>
                  </div>
                )}
                <div className="grade">
                  <span>Grade</span>
                  <button>
                    <SortIcon />
                  </button>
                </div>
                <div className="qty">
                  <span>Qty</span>
                  <button>
                    <SortIcon />
                  </button>
                </div>
                <div className="price">
                  <span>Price</span>
                  <button>
                    <SortIcon />
                  </button>
                </div>
                <div className="price">
                  <span>Total</span>
                  <button>
                    <SortIcon />
                  </button>
                </div>
              </div>

              {quote?.line_items_breakdown?.map((item, i) => {
                return (
                  <div
                    className={`items ${currentIndex === i ? "opened" : ""}`}
                    key={i}
                  >
                    <div className="table-item">
                      <div className="arrow">
                        {showMore && currentIndex === i ? (
                          <button
                            onClick={() => {
                              setShowMore(false);
                              setCurrentIndex(-1);
                            }}
                          >
                            <DropIcon />
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              setShowMore(true);
                              setCurrentIndex(i);
                            }}
                          >
                            <RightIcon />
                          </button>
                        )}
                      </div>
                      <div className="checkbox">
                        <button
                          onClick={() => {
                            selectItems(item, i);
                          }}
                        >
                          {item.approved ? <CheckFilled /> : <Checkbox />}
                        </button>
                      </div>
                      <div className="part">
                        <span>{item?.name}</span>
                      </div>
                      {review > 0 && (
                        <div className="image">
                          <span className="image_span">
                            <img
                              src={`https://platform.fixit45.com/storage/public/jobcard_fault/${imageUrls[0]}`}
                              alt=""
                            />
                          </span>
                        </div>
                      )}

                      <div className="grade">
                        <span>{item?.part_type}</span>
                      </div>
                      <div className="qty">
                        <span>{item?.quantity}</span>
                      </div>
                      <div className="price">
                        <span>
                          ₦
                          {item.markedup_price
                            ? numberWithCommas(item?.markedup_price)
                            : 0.0}
                        </span>
                      </div>
                      <div className="price">
                        <span>
                          ₦
                          {item.total_markedup_price
                            ? numberWithCommas(item?.total_markedup_price)
                            : 0.0}
                        </span>
                      </div>
                    </div>

                    <AnimatePresence>
                      {showMore && currentIndex === i ? (
                        <motion.div
                          className="more"
                          variants={subMenuAnimate}
                          initial="exit"
                          animate={showMore ? "enter" : "exit"}
                          exit="exit"
                        >
                          <div className="more_details">
                            <div className="quote_details">
                              <div className="item">
                                <span>Complaint</span>
                                <p>{job?.customer_complaint}</p>
                              </div>
                              <div className="item">
                                <span>Part/Services</span>
                                <p>{item?.name}</p>
                              </div>
                              <div className="item">
                                <span>Quantity</span>
                                <p>{item?.quantity}</p>
                              </div>
                              <div className="item">
                                <span>Unit</span>
                                <p>{item?.unit}</p>
                              </div>
                              <div className="item">
                                <span>Unit Price</span>
                                <p>
                                  ₦
                                  {item?.markedup_price
                                    ? numberWithCommas(item?.markedup_price)
                                    : 0.0}
                                </p>
                              </div>
                              <div className="item">
                                <span>Labour</span>
                                <p>
                                  ₦
                                  {item?.markedup_labour
                                    ? numberWithCommas(item?.markedup_labour)
                                    : 0.0}
                                </p>
                              </div>
                              <div className="item">
                                <span>Total</span>
                                <p>
                                  ₦
                                  {item?.total_markedup_price
                                    ? numberWithCommas(
                                        item?.total_markedup_price
                                      )
                                    : 0.0}
                                </p>
                              </div>
                              <div className="item">
                                <span>Part Grade</span>
                                <p>{item?.part_type}</p>
                              </div>
                              <div className="item">
                                <span>Observation</span>
                                <p>{item?.ta_observation}</p>
                              </div>
                              <div className="item">
                                <span>Recommendation</span>
                                <p>{item?.ta_recommended_action}</p>
                              </div>
                            </div>
                            {review > 0 && (
                              <ImageList
                                sx={{ width: "50%", height: "100%" }}
                                cols={1}
                                rowHeight={"auto"}
                              >
                                {imageUrls.map((item, index) => (
                                  <ImageListItem key={index}>
                                    <img
                                      src={`https://platform.fixit45.com/storage/public/jobcard_fault/${item}?w=164&h=164&fit=crop&auto=format`}
                                      srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                      alt={item.title ? item.title : ""}
                                      loading="lazy"
                                    />
                                  </ImageListItem>
                                ))}
                              </ImageList>
                            )}
                          </div>
                        </motion.div>
                      ) : null}
                    </AnimatePresence>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </View>
      <ModalLoader showModal={loading} />
    </Layout>
  );
};

export default QuoteDetails;

const View = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background-color: ${({ theme }) => theme.grey_100};
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 0 30px;
  padding-top: 60px;

  &::-webkit-scrollbar {
    display: none;
  }

  margin: 0;

  @media ${device.tablet} {
    width: 100vw;
    padding: 0 40px;
    padding-top: 60px;
  }

  @media ${device.phone} {
    width: 100vw;
    padding: 0 20px;
    padding-top: 60px;
  }

  .back {
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
    margin-top: 20px;
    cursor: pointer;
  }

  .content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 30px;
    flex-direction: column;

    @media ${device.phone} {
      width: 100%;
      flex-direction: column;
      margin-top: 30px;
    }

    .summary {
      width: 40%;
      display: flex;
      flex-direction: column;
      background-color: ${({ theme }) => theme.white};
      margin-bottom: 30px;
      border-radius: 4px;
      padding: 20px;

      @media ${device.phone} {
        width: 100%;
      }

      .head {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
      }
      .list {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;

        p {
          font-size: 0.875rem;
          font-weight: 500;
          color: ${({ theme }) => theme.grey_400};
        }
      }
      .total {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 20px;
        border-top: 1px solid ${({ theme }) => theme.grey_200};
      }
    }

    .col1 {
      width: 35%;

      padding: 20px;
      background-color: ${({ theme }) => theme.white};
      box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
      border-radius: 4px;

      @media ${device.phone} {
        width: 100%;
        margin-bottom: 20px;
      }

      h4 {
        font-weight: 400;
        margin-bottom: 20px;
      }

      .item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px;
        border-bottom: 1px solid ${({ theme }) => theme.grey_200};

        span {
          color: ${({ theme }) => theme.grey_400};
          font-weight: 500;
        }
        p {
          color: ${({ theme }) => theme.grey_700};
          font-weight: 500;
        }
      }
    }

    .col2 {
      width: 100%;
      padding: 20px;
      background-color: ${({ theme }) => theme.white};
      box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
      border-radius: 4px;

      @media ${device.phone} {
        width: 100%;
        overflow-x: scroll;
      }

      .info {
        width: 100%;
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;

        p {
          font-size: 0.875rem;
          margin-left: 5px;
          color: ${({ theme }) => theme.grey_400};
        }
      }

      .header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h3 {
          color: ${({ theme }) => theme.grey_700};
          font-weight: 500;
        }

        button {
          padding: 8px 14px;
          background-color: ${({ theme }) => theme.black};
          border: none;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: ${({ theme }) => theme.white};
          cursor: pointer;

          span {
            margin-left: 10px;
          }
        }
      }

      .table-view {
        margin: 20px 0;
        @media ${device.phone} {
          width: 200%;
          /* overflow-x: scroll; */
        }

        .table-head {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: ${({ theme }) => theme.grey_100};
          padding: 12px;
          margin-top: 20px;
          color: ${({ theme }) => theme.grey_500};

          div {
            flex: 1;
            display: flex;
            align-items: center;
            font-size: 0.75rem;
            font-weight: 600;

            button {
              background-color: transparent;
              padding: 0;
              outline: none;
              border: none;
              cursor: pointer;
              margin-left: 5px;
            }
          }

          .part {
            flex: 0.8;
          }

          .grade {
            flex: 0.7;
          }
          .qty {
            flex: 0.3;
          }
          .price {
            flex: 0.5;
          }
          .arrow {
            flex: 0.2;
          }
          .checkbox {
            flex: 0.2;
          }
        }

        .opened {
          background-color: ${({ theme }) => theme.grey_100};
        }

        .items {
          padding: 12px;
          width: 100%;
          transition: all 0.3s ease-in-out;

          .table-item {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: ${({ theme }) => theme.grey_700};

            div {
              flex: 1;
              display: flex;
              align-items: center;
              font-size: 0.875rem;
              font-weight: 400;

              button {
                background-color: transparent;
                padding: 0;
                outline: none;
                border: none;
                cursor: pointer;
                margin-left: 5px;
              }
            }

            .part {
              flex: 0.8;
            }

            .grade {
              flex: 0.7;
            }
            .qty {
              flex: 0.3;
            }
            .price {
              flex: 0.5;
            }
            .arrow {
              flex: 0.2;
            }
            .checkbox {
              flex: 0.2;
            }

            .open {
              color: ${({ theme }) => theme.blue_600};
              background-color: ${({ theme }) => theme.blue_50};
            }
            .closed {
              color: ${({ theme }) => theme.green};
              background-color: ${({ theme }) => theme.green_50};
            }
          }

          .more {
            width: 100%;
            padding: 12px;
            background-color: ${({ theme }) => theme.white};
            border-radius: 4px;
            margin-top: 20px;

            .item {
              width: 100%;
              display: flex;
              align-items: flex-start;
              justify-content: space-between;
              margin-bottom: 20px;

              span {
                max-width: 30%;
                font-size: 1rem;
                color: ${({ theme }) => theme.grey_400};
              }

              p {
                width: 65%;
                text-transform: capitalize;
              }
            }

            .cta {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: flex-end;

              button {
                background-color: ${({ theme }) => theme.grey_900};
                color: ${({ theme }) => theme.white};
                width: 160px;
                border-radius: 4px;
                border: none;
                outline: none;
                cursor: pointer;
                height: 35px;
              }
            }
          }
        }
      }
    }
  }
`;
