import styled from "styled-components";
import { device } from "../constants/breakpoints";

export const View = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background-color: ${({ theme }) => theme.grey_100};
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }

  @media ${device.tablet} {
    width: 100vw;
    padding: 0 0px;
    margin: 0;
  }

  @media ${device.phone} {
    width: 100vw;
    padding: 0 20px;
    padding-top: 60px;
  }

  .header {
    width: 100%;
    position: fixed;
    height: 50px;
    background-color: ${({ theme }) => theme.white};
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.grey_200};
    .icon {
      display: none;
      background-color: transparent;
      border: none;
      padding: 0;
      outline: none;
      cursor: pointer;
      margin-right: 10px;

      @media ${device.tablet} {
        display: block;
      }
      @media ${device.phone} {
        display: block;
      }
    }

    h4 {
      font-size: 1.25rem;
      font-weight: 500;
      padding: 0 30px;
    }
  }

  .tab-view {
    width: 100%;
    display: flex;
    position: fixed;
    top: 50px;
    background-color: ${({ theme }) => theme.white};
    padding: 20px 30px 0 30px;

    @media ${device.tablet} {
      margin-top: 0px;
      width: 100vw;
      padding: 20px 40px 0 40px;
    }
    @media ${device.phone} {
      margin-top: 10px;
    }

    button {
      padding: 6px 0;
      margin-right: 20px;
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      font-weight: 600;
      color: ${({ theme }) => theme.grey_400};
      font-size: 0.9rem;
      transition: all 0.2s ease-in-out;
      border-bottom: 2px solid ${({ theme }) => theme.white};
    }

    .active {
      color: ${({ theme }) => theme.blue_600};
      border-bottom: 2px solid ${({ theme }) => theme.blue_600};
      transition: all 0.2s ease-in-out;
    }
  }

  .content {
    width: 100%;
    height: 100%;
    padding: 0 30px;
    padding-top: 150px;

    @media ${device.phone} {
      padding: 20px;
      padding-top: 220px;
    }
  }
`;
