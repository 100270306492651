import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Loader from "react-loader-spinner";
import { toast } from "react-hot-toast";
import { View } from "../styles/signup.styles";
import { ReactComponent as LogoIcon } from "../assets/images/logoWhite.svg";
import { ReactComponent as ShowIcon } from "../assets/icons/show-icon.svg";
import { ReactComponent as HideIcon } from "../assets/icons/hide-icon.svg";
import { ReactComponent as Checkbox } from "../assets/icons/checkbox.svg";
import { ReactComponent as Checkedbox } from "../assets/icons/checkbox-filled.svg";
import { useDispatch, useSelector } from "react-redux";
import { registerUser, userSelector } from "../redux/features/userSlice";
import { useHistory } from "react-router-dom";

const schema = yup
  .object({
    email: yup.string().email("Must be a valid email").required(),
    password: yup.string().required(),
    name: yup.string().required(),
    phone: yup
      .string()
      .required()
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Phone number is not valid"
      ),
  })
  .required();

const Signup = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading, error, isAuthenticated } = useSelector(userSelector);
  const [showPassword, setShowPassword] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = (data) => {
    if (agreedToTerms) {
      dispatch(registerUser(data));
    } else {
      toast.error("Agree to our terms of service to proceed");
    }
  };

  useEffect(() => {
    error && toast.error(error);
  }, [error]);

  // redirect user to home page after successful
  useEffect(() => {
    if (isAuthenticated) {
      history.push("/dashboard");
    } else return;
  }, [isAuthenticated, history]);

  return (
    <View>
      <div className='content'>
        <div className='left-side'>
          <LogoIcon />
          <div className='info'>
            <h2>Create an account</h2>
            <p>Let’s get you all set up.</p>
          </div>
        </div>
        <div className='right-side'>
          <div className='form'>
            <h3>Sign Up</h3>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* ==== Name ===== */}
              <div className='input-view'>
                <label htmlFor='email'>Full name</label>
                <input
                  type='text'
                  placeholder='Enter fullname'
                  {...register("name")}
                />

                <p>{errors.name?.message}</p>
              </div>

              {/* ==== Email ===== */}
              <div className='input-view'>
                <label htmlFor='email'>Email Address</label>
                <input
                  type='text'
                  placeholder='Enter email address'
                  {...register("email")}
                />

                <p>{errors.email?.message}</p>
              </div>

              {/* ==== Phone ===== */}
              <div className='input-view'>
                <label htmlFor='phone'>Phone Number</label>
                <input
                  type='text'
                  placeholder='Enter phone number'
                  {...register("phone")}
                />

                <p>{errors.phone?.message}</p>
              </div>

              {/* ==== Address ===== */}
              {/* <div className='input-view'>
                <label htmlFor='address'>Address</label>
                <input
                  type='text'
                  placeholder='Enter your address'
                  {...register("address")}
                />

                <p>{errors.address?.message}</p>
              </div> */}

              {/* ===== Password ====== */}
              <div className='input-view'>
                <label htmlFor='email'>Password</label>
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder='Enter password'
                  {...register("password")}
                />
                {showPassword ? (
                  <button
                    type='button'
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <HideIcon />
                  </button>
                ) : (
                  <button
                    type='button'
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <ShowIcon />
                  </button>
                )}
                <p>{errors.password?.message}</p>
              </div>

              <div className='terms'>
                <button
                  type='button'
                  onClick={() => setAgreedToTerms(!agreedToTerms)}
                >
                  {agreedToTerms ? <Checkedbox /> : <Checkbox />}
                </button>
                <p>
                  I agree to the <a href='/'>Terms of Service</a> and{" "}
                  <a href='/'>Privacy Policy</a>
                </p>
              </div>

              <button type='submit' className='btn'>
                {loading ? (
                  <Loader
                    type='Oval'
                    color='#FFF'
                    height={35}
                    width={35}
                    timeout={10000}
                  />
                ) : (
                  "Create account"
                )}
              </button>
            </form>
            {/* ========== Register =========== */}
            <div className='register'>
              <span>Already have an account? </span>{" "}
              <Link to='/login'>Login</Link>
            </div>
          </div>
        </div>
      </div>
    </View>
  );
};

export default Signup;
