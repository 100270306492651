import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Loader from "react-loader-spinner";
import { toast } from "react-hot-toast";

import Layout from "../components/Layout";
import { View } from "../styles/book.styles";
import PageHeader from "../components/shared/PageHeader";
import {
  bookingSelector,
  fetchWorkshops,
  searchWorkshops,
  submitBooking,
} from "../redux/features/bookingSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchVehicles } from "../redux/features/vehicleSlice";
import { ReactComponent as DropIcon } from "../assets/icons/drop-icon.svg";

const schema = yup
  .object({
    booking_date: yup.string().required("Date and Time is required"),
    city: yup.string().required("City is required"),
  })
  .required();

const BookDiagnosis = () => {
  const dispatch = useDispatch();
  const { loading, message, error, workshops } = useSelector(bookingSelector);
  // const { vehicles } = useSelector(vehicleSelector);
  const [workshop, setWorkshop] = useState("");
  const [query, setQuery] = useState("");
  const [search, setSearch] = useState("");
  const [openMenu, setOpenMenu] = useState(false);
  const [city, setCity] = useState("");
  const [nearestWorkshop, setNearestWorkshop] = useState("");

  useEffect(() => {
    if (query !== "") {
      dispatch(searchWorkshops(query));
    } else return;
  }, [query, dispatch]);

  useEffect(() => {
    if (city) {
      const workshop = workshops.filter((item) => item.city.toLowerCase() === city.toLowerCase());
      setNearestWorkshop(workshop);
    } else return;
  }, [city, workshops]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    dispatch(fetchVehicles());
    dispatch(fetchWorkshops());
  }, [dispatch]);

  const onSubmit = (data, e) => {
    const form = {
      ...data,
      workshop_id: workshop?.id,
    };
    dispatch(submitBooking(form));
    e.target.reset();
  };

  useEffect(() => {
    error && toast.error(error);
  }, [error]);

  useEffect(() => {
    message && toast.success(message);
  }, [message]);

  return (
    <Layout>
      <View>
        <PageHeader title="Book an Appointment" />

        <div className="content">
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* =====vehicle make ==== */}

            {/* ===== City ==== */}
            <div className="input-view">
              <label htmlFor="city">
                City <span>*</span>
              </label>
              <input
                type="text"
                placeholder="e.g Ikeja"
                {...register("city")}
                onChange={(e) => setCity(e.target.value)}
              />

              <p>{errors.city?.message}</p>
            </div>

            {/* ===== Booking Date ==== */}
            <div className="input-view">
              <label htmlFor="city">Appointment Date</label>
              <input
                type="datetime-local"
                min={new Date().toISOString().slice(0, 16)}
                placeholder="choose an appointment date"
                {...register("booking_date")}
              />
              <p>{errors.booking_date?.message}</p>
            </div>

            {/* ===== Workshop ==== */}
            <div className="input-view workshops">
              <label htmlFor="workshop_id">workshop(optional)</label>
              <DropIcon className="icon" />

              <input
                type="text"
                placeholder="Choose a workshop"
                disabled={!city}
                value={search}
                onChange={(e) => {
                  setQuery(e.target.value);
                  setSearch(e.target.value);
                }}
                onClick={() => setOpenMenu(!openMenu)}
              />

              {openMenu && (
                <div className="options">
                  {nearestWorkshop?.map((item) => {
                    return (
                      <div
                        key={item?.id}
                        value={item?.id}
                        className="option"
                        onClick={() => {
                          setWorkshop(item);
                          setOpenMenu(false);
                          setSearch(`FIXIT45, ${item?.partner_id} ${item?.city}`);
                        }}
                      >
                        {`${"FIXIT45"}  ${item?.partner_id} ${item?.city}`},
                      </div>
                    );
                  })}
                </div>
              )}
            </div>

            {/* ===== VIN ==== */}
            {/* <div className='input-view'>
                <label htmlFor='vin'>VIN</label>
                <input
                  type='text'
                  placeholder='Enter your VIN'
                  {...register("vin", {
                    maxLength: 17,
                  })}
                />
              </div> */}

            {/* ===== Company ID ==== */}
            {/* <div className='input-view'>
                <label htmlFor='company_id'>Company ID</label>
                <input
                  type='text'
                  placeholder='Enter your comapny id'
                  {...register("company_id")}
                />
                <p>{errors.company_id?.message}</p>
              </div> */}

            <div className="row">
              {/* ===== Workshop ID ==== */}
              {/* <div className='input-view'>
                <label htmlFor='workshop_id'>Workshop ID</label>
                <input
                  type='text'
                  placeholder='Enter your workshop id'
                  {...register("workshop_id")}
                />
                <p>{errors.workshop_id?.message}</p>
              </div> */}
            </div>

            <div className="cta">
              <button type="submit" className="btn">
                {loading ? (
                  <Loader
                    type="Oval"
                    color="#FFF"
                    height={35}
                    width={35}
                    timeout={10000}
                  />
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </form>
        </div>
      </View>
    </Layout>
  );
};

export default BookDiagnosis;
