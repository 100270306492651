import { createSlice } from "@reduxjs/toolkit";
import api from "../../helpers/api";

const initialState = {
  loading: false,
  isAuthenticated: false,
  user: null,
  error: null,
  message: null,
  showLink: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    fetch: (state) => {
      state.loading = true;
      state.message = null;
      state.error = null;
    },
    login: (state) => {
      state.isAuthenticated = true;
      state.loading = false;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.loading = false;
    },
    register: (state) => {
      state.registered = true;
      state.isAuthenticated = true;
      state.loading = false;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    setMessage: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
    },
    clear: (state) => {
      state.loading = false;
      state.error = null;
      state.message = null;
    },
    setUser: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.user = payload;
    },
    setShowLink: (state, { payload }) => {
      state.showLink = payload;
    },
  },
});

export const {
  login,
  setError,
  fetch,
  setMessage,
  register,
  clear,
  logout,
  setUser,
  setShowLink,
} = userSlice.actions;
export default userSlice.reducer;
export const userSelector = (state) => state.user;

// Register User
export function registerUser(user) {
  return async (dispatch) => {
    dispatch(fetch());

    try {
      const res = await api.post("/auth/register", user);
      dispatch(setMessage("Registration Successful"));
      dispatch(register());

      localStorage.setItem("@UT", res?.data?.data?.token);
      localStorage.setItem("@isAuth", "true");
      dispatch(clear());
    } catch (error) {
      if (error?.response?.status === 500) {
        dispatch(setError("Something went wrong, please try again"));
      } else {
        const errors = error.response.data.errors;
        Object.keys(errors).map((error) =>
          dispatch(setError(errors[error][0]))
        );
      }
      dispatch(clear());
    }
  };
}

// Login User
export function loginUser(user) {
  return async (dispatch) => {
    dispatch(fetch());
    localStorage.removeItem("@UT");

    try {
      const res = await api.post("/auth/login", user);
      dispatch(setMessage("Login Successful"));
      dispatch(login());
      localStorage.setItem("@isAuth", "true");

      dispatch(fetchProfile());
      localStorage.setItem("@UT", res?.data?.data?.token);
    } catch (error) {
      if (error?.response?.status === 500) {
        dispatch(setError("Something went wrong, please try again"));
      } else {
        await dispatch(setError("Invalid email or password"));
      }
    }
    dispatch(clear());
  };
}

// Fetch User
export function fetchProfile() {
  return async (dispatch) => {
    dispatch(fetch());

    try {
      const res = await api.get("/user");
      dispatch(setUser(res.data.data));
      localStorage.setItem("@user", res?.data?.data);
      localStorage.setItem("@isAuth", "true");
    } catch (error) {
      if (error?.response?.status === 500) {
        dispatch(setError("Something went wrong, please try again"));
      } else {
        dispatch(setError(error?.response?.data?.message));
      }
      dispatch(clear());
      if (error?.response?.status === 401) {
        dispatch(logoutUser());
      }
    }
  };
}

// Update Profile
export function updateUser(user) {
  return async (dispatch) => {
    dispatch(fetch());

    try {
      await api.post("/user/update", user);
      dispatch(setMessage("Profile update Successful"));

      dispatch(fetchProfile());
    } catch (error) {
      if (error?.response?.status === 500) {
        dispatch(setError("Something went wrong, please try again"));
      } else {
        const errors = error?.response?.data?.errors;
        Object.keys(errors).map((error) =>
          dispatch(setError(errors[error][0]))
        );
      }
    }
    dispatch(clear());
  };
}

// Update Password
export function updatePassword(user) {
  return async (dispatch) => {
    dispatch(fetch());

    try {
      await api.post("/user/password/change", user);
      dispatch(setMessage("Password updated Successfully"));

      dispatch(fetchProfile());
    } catch (error) {
      if (error?.response?.status === 500) {
        dispatch(setError("Something went wrong, please try again"));
      } else {
        const errors = error?.response?.data?.errors;
        Object.keys(errors).map((error) =>
          dispatch(setError(errors[error][0]))
        );
      }
    }
    dispatch(clear());
  };
}

// Update Password
export function resetPassword(email) {
  return async (dispatch) => {
    dispatch(fetch());

    try {
      const res = await api.post("/auth/password/email", email);
      dispatch(setMessage(res.data.message));

      dispatch(setShowLink(res.data.url));
    } catch (error) {
      if (error?.response?.status === 500) {
        dispatch(setError("Something went wrong, please try again"));
      } else {
        const errors = error?.response?.data?.errors;
        Object.keys(errors).map((error) =>
          dispatch(setError(errors[error][0]))
        );
      }
    }
    dispatch(clear());
  };
}

// Confirm Password Reset
export function confirmPasswordReset(user) {
  return async (dispatch) => {
    dispatch(fetch());

    try {
      await api.post("/auth/password/reset", user);
      // dispatch(setMessage(res.data.message));
      dispatch(setMessage("Password reset successful, Proceed to login"));
    } catch (error) {
      if (error?.response?.status === 500) {
        dispatch(setError("Something went wrong, please try again"));
      } else {
        const errors = error?.response?.data?.errors;
        Object.keys(errors).map((error) =>
          dispatch(setError(errors[error][0]))
        );
      }
    }
    dispatch(clear());
  };
}

// Logout User
export function logoutUser() {
  return async (dispatch) => {
    localStorage.clear();
    dispatch(logout());
    window.location = "/login";
  };
}
