import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { usePaystackPayment } from "react-paystack";
import styled from "styled-components";
import {
  createSubscription,
  subscriptionsSelector,
} from "../../redux/features/subscriptionSlice";
import { ReactComponent as CheckedIcon } from "../../assets/icons/checked.svg";
import { ReactComponent as EmptyIcon } from "../../assets/icons/empty.svg";
import { userSelector } from "../../redux/features/userSlice";
import ModalLoader from "../shared/Loader";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import { device } from "../../constants/breakpoints";

const Plans = ({ vehicle }) => {
  const dispatch = useDispatch();
  const { subscriptions, loading, message, error } = useSelector(
    subscriptionsSelector
  );
  const { user } = useSelector(userSelector);
  const [trxRef, setTrxRef] = useState(Date.now());
  const [amount, setAmount] = useState("");
  const [sub_id, setSubID] = useState("");

  // ======= PAYSTACK CONFIG ==========
  let config = {
    reference: trxRef,
    email: user?.email,
    amount: amount * 100,
    publicKey: process.env.REACT_APP_PAYSTACK_KEY,
    channels: ["card"],
  };

  const initializePayment = usePaystackPayment(config);

  useEffect(() => {
    const handleSubscriptions = () => {
      if (amount) {
        initializePayment(onSuccess, onCancle);
      } else return;
    };

    handleSubscriptions();

    // eslint-disable-next-line
  }, [amount]);

  /**
   * If payment wasn't successful
   */
  const onCancle = () => {
    setAmount("");
    setTrxRef("");
  };

  /**
   * If payment wasn't successful
   */
  const onSuccess = (reference) => {
    setAmount("");
    setTrxRef("");

    const paystackRef = reference.reference;
    const data = {
      reference: paystackRef,
      subscription_id: sub_id,
      vin: vehicle?.vin,
      license_no: vehicle?.license_no,
      // medium: "card",
    };

    dispatch(createSubscription(data));
  };

  useEffect(() => {
    error && toast.error(error);
  }, [error]);

  useEffect(() => {
    message && toast.success(message);
  }, [message]);

  return (
    <View>
      {subscriptions.length > 0 ? (
        <div className='subs'>
          {subscriptions?.map((sub) => {
            return (
              <div className='sub' key={sub?.id}>
                <div className='head'>
                  <p>{sub?.subscription_type?.name}</p>
                  <h3>₦{sub?.amount}</h3>
                </div>

                <div className='features'>
                  <p>Features</p>

                  {sub?.subscription_type?.services.map((item) => {
                    return (
                      <div className='list'>
                        <CheckedIcon />
                        <span>{item?.name}</span>
                      </div>
                    );
                  })}
                </div>

                <div className='cta'>
                  <button
                    onClick={() => {
                      setAmount(parseInt(sub?.amount));
                      setSubID(sub?.id);
                    }}
                  >
                    Subscribe
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className='empty'>
          <EmptyIcon />
          <p>No subscription plan for this vehicle yet.</p>
        </div>
      )}
      <ModalLoader showModal={loading} />
    </View>
  );
};

export default Plans;

const View = styled.div`
  width: 100%;
  height: 100%;

  .empty {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p {
      margin-top: 5px;
    }
  }

  .subs {
    width: 100%;
    display: flex;
    align-items: flex-start;
    /* justify-content: space-between; */
    flex-wrap: wrap;

    .sub {
      width: 30%;
      background-color: ${({ theme }) => theme.white};
      border-radius: 16px;
      border: 1px solid ${({ theme }) => theme.grey_200};
      min-height: 60vh;
      margin-right: 30px;

      @media ${device.tablet} {
        width: 48%;
        margin-right: 15px;
      }
      @media ${device.phone} {
        width: 100%;
      }

      .head {
        width: 100%;
        padding: 20px;
        border-bottom: 1px solid ${({ theme }) => theme.grey_200};

        p {
          font-size: 1.25rem;
          color: ${({ theme }) => theme.grey_500};
        }
        h3 {
          font-size: 2.5rem;
          font-weight: 500;
          margin-top: 10px;
        }
      }

      .features {
        width: 100%;
        padding: 20px;

        p {
          font-size: 1.25rem;
          color: ${({ theme }) => theme.grey_500};
          text-transform: uppercase;
          margin-bottom: 20px;
        }

        .list {
          width: 100%;
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          span {
            margin-left: 10px;
          }
        }
      }

      .cta {
        width: 100%;
        padding: 20px;
        button {
          background-color: ${({ theme }) => theme.black};
          color: ${({ theme }) => theme.white};
          width: 100%;
          border: none;
          outline: none;
          cursor: pointer;
          height: 50px;
          border-radius: 8px;
        }
      }
    }
  }
`;
