import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import ModalLoader from "../../components/shared/Loader";
import { vehicleSelector } from "../../redux/features/vehicleSlice";
import { setView } from "../../redux/features/subscriptionSlice";
import styled from "styled-components";
import { device } from "../../constants/breakpoints";

const Overview = () => {
  const dispatch = useDispatch();
  const { vehicle, loading } = useSelector(vehicleSelector);

  return (
    <View>
      <div className='content'>
        {!vehicle?.subscription ? (
          <div className='vehicles'>
            <div className='item'>
              <span>No Subscription</span>
              <h4>
                {vehicle?.year}, {vehicle?.make} {vehicle?.model}
              </h4>
              <p>{vehicle?.subscription?.subscription_type?.name}</p>
              <span>{vehicle?.vin}</span>

              <div className='cta'>
                <button
                  onClick={() => {
                    dispatch(setView(1));
                  }}
                >
                  Subscribe Now
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className='vehicles'>
            <div className='item'>
              {/* <span>Current subscription plan</span> */}
              <p>{vehicle?.subscription?.subscription_type?.name}</p>
              <h4>
                {vehicle?.year}, {vehicle?.make} {vehicle?.model}
              </h4>

              <span>{vehicle?.vin}</span>

              <div className='cta'>
                <button
                  onClick={() => {
                    dispatch(setView(1));
                  }}
                >
                  Upgrade Plan
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <ModalLoader showModal={loading} />
    </View>
  );
};

export default Overview;

const View = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background-color: ${({ theme }) => theme.grey_100};

  .content {
    width: 100%;
    height: 100%;
    padding: 0;

    .vehicles {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 20px;

      .item {
        width: 32%;
        background-color: ${({ theme }) => theme.white};
        border-radius: 4px;
        padding: 15px;
        margin-bottom: 20px;
        min-height: 200px;

        @media ${device.tablet} {
          width: 100%;
        }
        @media ${device.phone} {
          width: 100%;
        }

        h4 {
          margin-top: 15px;
          margin-bottom: 8px;
          font-size: 1.4rem;
          font-weight: 500;
        }

        span,
        p {
          color: ${({ theme }) => theme.grey_400};
        }

        .cta {
          width: 100%;
          display: flex;
          button {
            margin-top: 20px;
            width: 100%;
            height: 40px;
            background-color: ${({ theme }) => theme.black};
            border: none;
            border-radius: 4px;
            border: 1px solid ${({ theme }) => theme.grey_200};
            color: ${({ theme }) => theme.white};
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            cursor: pointer;
          }
        }
      }
    }
  }
`;
